import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'
import ReactPlayer from 'react-player'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/NavbarAlt'
// import Video from '../../images/grupo_1.png'
import Footer from '../Footer'
import {
    BodyContainer,
    BodyWrapper,
    TopSection,
    MiddleSection,
    BottomSection,
    CuentasOKTitle,
    CuentasOKSubTitle,
    CuentasOkButton,
    ColumnRight,
    ColumnLeft,
    LogoCOK,
    LogoTivos,
    VideoButton,
    TivosSubTitle,
    BottomSectionTitle,
    LabelColumnLeft,
    LabelColumnRight,
    // GreenTivos,
    // BlueCuentas,
    BottomColumn,
    BottomImg,
    Video,
    CloseButton,
    Disclaimer
} from "../CuentasOk/Elements"

import LogoCuentas from '../../images/palomita.png'
import LogoTiv from '../../images/tivos white back.png'
import CokTivos from '../../images/tivosCok.png'

const CuentasOk = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [mute, setMute] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    scroll.scrollToTop();

    const showVideo = () => {
        if(!show){
            setShow(!show)
            if(!mute){
                setMute(mute)
            }
        }else{
            setShow(!show)
            setMute(!mute)
        };
    }

    const goToCuentasOK = () => {
        window.location = "https://www.cuentasok.com/registro"
    };

    const VideoStyles = {
        position:'absolute',
        width:'700px !important'
    }

  return (
    <>
        <Sidebar isOpen = {isOpen} toggle={toggle} />
        <Navbar toggle={toggle}/> 

        <Video show={show}>
            <ReactPlayer style={VideoStyles} height='100%' width='100%' url="https://www.youtube.com/watch?v=98exW8hqnGc&t=2s" controls={true} muted={mute} playing={show} />
            <CloseButton onClick={showVideo}>Cerrar</CloseButton>
        </Video>

        <BodyContainer>
            <BodyWrapper>
                <TopSection>
                    <ColumnLeft>
                        <CuentasOKTitle>&#161;Nos aliamos con <colorFont style={{color:'#0ec2f0'}}>CuentasOK</colorFont> para facilitarte arrendamiento puro&#33;</CuentasOKTitle>
                        <CuentasOKSubTitle>Somos la solución de servicios financieros en una sola plataforma.</CuentasOKSubTitle>
                        <CuentasOkButton extraSmall marginL onClick={goToCuentasOK}>&#161;Comienza ahora&#33;</CuentasOkButton>
                    </ColumnLeft>
                    <ColumnRight>
                        <LogoCOK src={LogoCuentas}/>
                        <LogoTivos src={LogoTiv}/>
                    </ColumnRight>
                </TopSection>
                <MiddleSection>
                    <TivosSubTitle>Crece tu negocio exponencialmente con nosotros</TivosSubTitle>
                    <VideoButton onClick={showVideo}>Ver video</VideoButton>
                </MiddleSection>
                <BottomSection>
                    <BottomSectionTitle>Con tu cuenta <strong>tivos</strong><colorFont style={{color:'#1EB445'}}>^</colorFont> y <strong><colorFont style={{color:'#0ec2f0'}}>Cuentas</colorFont><colorFont style={{color:'#0c61bb'}}>OK</colorFont></strong> tendr&aacute;s acceso a:</BottomSectionTitle>
                    <BottomColumn>
                        <LabelColumnLeft>Organiza las Cuentas. Gana confianza en tu gestión financiera.</LabelColumnLeft>
                    </BottomColumn>
                    <BottomColumn>
                        <BottomImg src={CokTivos}/>
                        <CuentasOkButton medium onClick={goToCuentasOK}>&#161;Comienza ahora&#33;</CuentasOkButton>
                        <Disclaimer>*Usuario de prueba por 1 mes en <colorFont style={{color:'#0ec2f0'}}>CuentasOK</colorFont></Disclaimer>
                    </BottomColumn>
                    <BottomColumn>
                        <LabelColumnRight>Personalizamos el arrendamiento puro a tasas altamente competitivas</LabelColumnRight>
                    </BottomColumn>
                </BottomSection>
            </BodyWrapper>
        </BodyContainer>
        <Footer />
    </>
  )
}

export default CuentasOk