//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom'
import { 
    FooterContainer, 
    FooterWrapp, 
    FooterLinksContainer, 
    FooterLinksWrapper, 
    FooterLinkItems, 
    // FooterLinkTitle, 
    FooterLink,
    FooterLinkPage, 
    SocialMedia, 
    SocialMediaWrapp, 
    // SocialLogo, 
    WebsiteRights, 
    SocialIcons, 
    SocialIconLink,
    FooterLinkEx,
    FooterLogo,
    Logo } 
from 
'./FooterElements';
import {
    // FaFacebook, 
    // FaInstagram, 
    // FaYoutube, 
    // FaTwitter, 
    FaLinkedin
} 
from 'react-icons/fa';
import mainLogoL from '../../images/tivos-light-logo.png'
// import { animateScroll as scroll } from 'react-scroll/modules'

const goToPreRegistro = () => {
    window.location = "./Solicitud"
}

const goToPreCuentasOk = () => {
    window.location = "./CuentasOk"
}

const Footer = () => {
    const navigate = useNavigate();

    const returnMain = () => {
        navigate('/');
        //document.getElementById('services').scrollIntoView({behavior : 'smooth'});
    }

    const [scrollNav, setScrollNav] = useState(false);
    
    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }
    
    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])
        
    return (
        <>
            <FooterContainer>
                <FooterWrapp>
                    <FooterLinksContainer>
                        <FooterLinksWrapper>
                            <FooterLinkItems>
                                {/* <FooterLinkTitle>Footer Column 1</FooterLinkTitle> */}
                                <FooterLink onClick={returnMain} scrollNav={scrollNav} to="services" smooth={true} duration={500} spy={true} exact='true' offset={-270}>Nosotros</FooterLink>
                                <FooterLink onClick={returnMain} scrollNav={scrollNav} to="industry" smooth={true} duration={500} spy={true} exact='true' offset={-70}>Soluciones</FooterLink>
                                <FooterLink onClick={returnMain} scrollNav={scrollNav} to="simulador" smooth={true} duration={500} spy={true} exact='true' offset={-70}>Simulador</FooterLink>
                                {/* <FooterLinkEx href="http://help.tivos.mx/es" target="_blank">FAQ's</FooterLinkEx>
                                <FooterLinkEx onClick={goToPreCuentasOk} target="_blank">CuentasOK</FooterLinkEx> */}
                            </FooterLinkItems>
                            <FooterLinkItems>
                                {/* <FooterLinkTitle>Footer Column 2</FooterLinkTitle> */}
                                <FooterLink onClick={goToPreRegistro}>Inicia tu Solicitud</FooterLink>
                                <FooterLinkPage to="/AvisoPrivacidad">Aviso de Privacidad</FooterLinkPage>
                                <FooterLinkPage to="/TerminosCondiciones">Términos y Condiciones</FooterLinkPage>
                            </FooterLinkItems>
                            <FooterLogo>
                                <Logo scrollNav={scrollNav} src={mainLogoL} />
                            </FooterLogo>
                        </FooterLinksWrapper>
                    </FooterLinksContainer>
                    <SocialMedia>
                        <SocialMediaWrapp>
                            {/* <SocialLogo>Tivos^</SocialLogo> */}
                            <WebsiteRights>tivos^ © {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
                            <SocialIcons>
                                {/* <WebsiteRights>Encuentranos en:</WebsiteRights> */}
                                {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
                                <SocialIconLink href="/" target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
                                <SocialIconLink href="/" target="_blank" aria-label="YouTube"><FaYoutube /></SocialIconLink>
                                <SocialIconLink href="/" target="_blank" aria-label="Twitter"><FaTwitter /></SocialIconLink> */}
                                 <SocialIconLink href="https://www.linkedin.com/company/tivos-capital/" target="_blank" aria-label="LinkedIn"><FaLinkedin /></SocialIconLink>
                            </SocialIcons>
                        </SocialMediaWrapp>
                        <WebsiteRights>Culiac&aacute;n No. 123, interior 504, Colonia Hip&oacute;dromo, C&oacute;digo Postal 06100, Alcald&iacute;a Cuauht&eacute;moc, Ciudad de M&eacute;xico, México</WebsiteRights>
                    </SocialMedia>
                </FooterWrapp>
            </FooterContainer>
        </>
    )
}

export default Footer
