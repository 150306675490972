import styled from 'styled-components'
 
export const BodyContainer = styled.div`
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const BodyWrapper = styled.div`
    padding 2% 5%;
`;

export const BodyTitle = styled.h1`
    color: #1EB445;
    text-align: center;
    padding: 2% 20%;
`;

export const BodyParagraph = styled.p`
   margin: 10px auto;
   text-align: justify;
`;

export const BodySubTitle = styled.h3`
    color: #1EB445;
    font-weight: 600;
    margin-top: 25px;
`;

export const BodySubSubTitle = styled.h4`
    color: #1EB445;
    margin-bottom: 15px;
    font-weight: 300;
`;

export const BodyList = styled.ul`
    width: 100%;
    padding: 2% 5%;
    list-style: none;
`;

export const ListTitle = styled.strong`
    color: #1EB445;
`;

export const ListItem = styled.li`
    margin: 10px auto;
`;

export const BodyFooter = styled.p`
    font-weight: 600;
    margin-top: 5%;
    width: 17%;
`;
