import React, { useRef } from 'react';
import {
    ContactoContainer,
    ContactoWrapper,
    ContactoH1,
    ContactoHeaderP,
    ContactoRow//,
    // Column1,
    // Direccion,
    // Telefono,
    // Correo,
    // Copy,
    // Column2,
    // Form,
    // NombreInput,
    // CorreoInput,
    // MensajeInput
    // Button
} from './ContactoElements'
import emailjs from '@emailjs/browser'
import {Button} from '../ButtonElement'
const agenda = () => {
    window.location = "https://calendly.com/anasofia-tivos/"
}

const goToPreRegistro = () => {
    window.location = "./Solicitud"
}

const Contacto = () => {
    const form = useRef();

     // let from_name,from_email,from_message;
    
    const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_e28kszh', 'template_iittboq', form.current, 'user_WtBboO8xO9rTdQpVHGizr')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        e.target.reset();
    };

    return (
        <>
            <ContactoContainer id="contacto">
                <ContactoWrapper>
                    <ContactoH1>Buscamos exponenciar tu operación.</ContactoH1>
                    <ContactoHeaderP>Platiquemos del por qué entendemos mejor que nadie tus retos como empresario.</ContactoHeaderP>
                    <ContactoRow>
                        <Button small="true" marginB primary dark="true" onClick={goToPreRegistro}>Inicia tu Solicitud</Button>
                    </ContactoRow>
                    {/* <ContactoRow>
                        <Column1>
                            <Copy>Platiquemos del por qué entendemos mejor que nadie tus retos como empresario.</Copy>
                            <Telefono>Agenda una llamada con nuestros expertos:</Telefono>
                            <Button type="button" value="Aquí" primary={true} inline={true} onClick={agenda}></Button>
                            {/* <Direccion>Campeche 289, Hipódromo Cuauhtémoc, 06100 Ciudad de México, CDMX.</Direccion> */}
                        {/* </Column1>
                        <Column2>
                            <Form ref={form} onSubmit={sendEmail}>
                                <NombreInput type="text" placeholder='Nombre' name="from_name"></NombreInput>
                                <CorreoInput type="email" placeholder='Correo' name="from_email"></CorreoInput>
                                <MensajeInput placeholder='Mensaje...' name="from_message"></MensajeInput>
                                <Button type="submit" value="Enviar Mensaje" primary={true}></Button>
                            </Form>
                        </Column2>
                    </ContactoRow> */}
                </ContactoWrapper>
            </ContactoContainer>
        </>
    )
}

export default Contacto
