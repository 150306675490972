//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from 'styled-components'

export const SimuladorContainer = styled.div`
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;

    @media screen and (max-width: 1000px){
      height: 1200px;
    };

    @media screen and (max-width: 768px){
      margin-top: 530px;
    };

    @media screen and (max-width: 480px){
        height: 1300px;
        margin-top: 390px;
    };
`;

export const SimuladorH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 300;
    color: #1EB445;
    margin-top: 64px;
    margin-bottom: 20px;

    @media screen and (max-width: 1000px){
        text-align: center;
    }

    @media sceen and (max-width: 480){
        font-size: 2rem; 
    }
`;

export const SimuladorPH = styled.p`
    text-size: 1rem;
    text-align: center;
    color: gray; 

    @media screen and (max-width: 1000px){
    }
`;

export const SimuladorWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 550px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 1000px){
      height: 1200px;
    }

`;

export const SimuladorRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 1000px){
        grid-template-areas: 'col1' 'col2';
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 0px;
    grid-area: col1;

    @media screen and (max-width: 1200px){
      width: 700px;
    }

    @media screen and (max-width: 1100px){
      width: 600px;
    }

    @media screen and (max-width: 480px){
      width: 300px;
    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 0px;
    grid-area: col2;
    
    @media screen and (max-width: 1200px){
      width: 400px;
    }

    @media screen and (max-width: 1100px){
      width: 350px;
    }

    @media screen and (max-width: 1000px){
      width: 100%;
      grid-area: col2;
      margin-top: -150px;
    }

    @media screen and (max-width: 480px){
      width: 130%;
      grid-area: col2;
      margin-top: -150px;
      margin-left: -15%;
    }

    @media screen and (max-width: 380px){
      width: 120%;
      grid-area: col2;
      margin-top: -150px;
      margin-left: -10%;
    }
`;

export const SimuladorForm = styled.div`
`;

export const SimuladorH2 = styled.h2`
    font-weight: 300;
    margin-bottom: 27px;
`;

export const SimuladorSubTitulo = styled.p`
    margin-bottom: 5px;
`;

export const SimuladorValorLetra = styled.p`
    color: gray;
    font-size: 1.5em;
`;

export const SimuladorRango = styled.input`
  overflow: hidden;
  display: block;
  appearance: none;
  width: 700px;
  margin: 0;
  height: 36px;
  cursor: pointer;
  background: #f5f5f5;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 16px;
        background: #1EB445;
        border-radius: 50px;
      }

    &::-webkit-slider-thumb {
        position: relative;
        appearance: none;
        height: 26px;
        width: 26px;
        background: #FFF;
        border-radius: 100%;
        border: solid 2px #1EB445;
        top: 50%;
        transform: translateY(-50%);
        transition: background-color 150ms;
        }
        
  @media screen and (max-width: 1200px){
    width: 700px;
  }

  @media screen and (max-width: 1100px){
    width: 600px;
  }

  @media screen and (max-width: 480px){
    width: 300px;
  }
`;

export const SimuladorSummary = styled.div`
  height: 450px;
  padding: 10%;
`;

export const Division = styled.div`
  border-top: 2px solid #1EB445;
  margin-bottom: 20px;
`;

export const MontoArrendamiento = styled.p`
  color: gray;
  margin-bottom: 7%;
`;

export const MontoArrendamientoNumero = styled.p`
  color: #1EB445;
  font-weight: 600;
  font-size: 1.5em;
  margin-bottom: 7%;
  margin-left: 30%;
`;

export const PagosNumero = styled.p`
  color: #1EB445;
  font-weight: 600;
  width: 10%;
  float: left;

  @media screen and (max-width: 1000px){
    margin-left: 30%;
  }

  @media screen and (max-width: 480px){
    margin-left: 20%;
  }

`;

export const PagosNumeroLabel = styled.p`
  color: gray;
  margin-bottom: 7%;
`;

export const PagosWrapper = styled.div`
  border: solid 1px #1EB445;
  margin: 2.5%;
  height: 60px;
  border-radius: 4px;

`;

export const PagoLetra = styled.p`
  color: gray;
  float: left;
  margin-top: 7.5%;
  margin-left: 3%;

  @media screen and (max-width: 1000px){
    margin: 4%;
  }

  @media screen and (max-width: 480px){
    margin: 7%;
  }

`;

export const PagoCantidad = styled.p`
  color: #1EB445;
  margin-top: 7.5%;
  margin-left: 60%;

  @media screen and (max-width: 1000px){
    margin-top: 4%;
    margin-left: 70%;
  }

  @media screen and (max-width: 1000px){
    margin-top: 7%;
    margin-left: 60%;
  }

`;

export const SubText = styled.p`
  color: gray;
  font-size: .8em;
`;


