import React, {useState, useEffect} from 'react'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll/modules'
import {
    Nav, 
    NavbarContainer, 
    NavLogoWrapper, 
    NavLogoImg1, 
    NavLogoImg2, 
    // NavLogo, 
    MobileIcon, 
    NavMenu, 
    NavItem, 
    NavLinks, 
    NavLinksEx, 
    // NavBtnLink
    NavBtn, 
} 
from './navBarElements'
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib'
import mainLogoL from '../../images/tivos-dark-logo.png'
import mainLogoD from '../../images/tivosOk.png'

// const goToPreRegistro = () => {
    
//     let monto = document.getElementById("monto").value;
//     let plazo = document.getElementById("plazo").value;
//     let anticipo = document.getElementById("anticipo").value;
//     let residual = document.getElementById("residual").value;

//     localStorage.setItem('monto', monto)
//     localStorage.setItem('plazo', plazo)
//     localStorage.setItem('anticipo', anticipo)
//     localStorage.setItem('residual', residual)

//     window.location = "./PreRegistroForm"
// }

const goToPreCuentasOk = () => {
    window.location = "./CuentasOk"
}


const Navbar = ({ toggle }) => {
    
    const [show, setShow] = useState(true);
    const [show1, setShow1] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();

    const params1 = searchParams.get('cuentasOk') ?? '';

    function showLogo(){
        if(params1){ if(show){setShow(!show)} }else{ if(show1){setShow1(!show1)} }
    };

    showLogo();
    
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])

    const toggleHome = () => {
        scroll.scrollToTop();
    }
    
    const navigate = useNavigate();

    const returnMain = () => {
        navigate('/');
        //document.getElementById('services').scrollIntoView({behavior : 'smooth'});
    }

    return (
        <>
        <IconContext.Provider value={{ color: '#1EB445' }}>
            <Nav>
                <NavbarContainer>
                    {/* <NavLogo to="/" onClick={toggleHome}>Tivos^</NavLogo> */}
                    <NavLogoWrapper to="/" onClick={toggleHome}>
                        <NavLogoImg1 show={show} scrollNav={scrollNav} src={mainLogoL}/>
                        <NavLogoImg2 show={show1} scrollNav={scrollNav} src={mainLogoD}/>
                    </NavLogoWrapper>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks onClick={returnMain}>Nosotros</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks onClick={returnMain}>Soluciones</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks onClick={returnMain}>Simulador</NavLinks>
                        </NavItem>
                        {/* {/* <NavItem> */}
                            {/* <NavLinksEx href="http://help.tivos.mx/es" target="_blank">FAQ's</NavLinksEx> */}
                        {/* </NavItem> */}
                         <NavItem>
                             <NavLinksEx href="https://tivos.blog/">Blog</NavLinksEx>
                         </NavItem>
                    </NavMenu>
                    <NavBtn>
                        {/* <NavBtnLink onClick={goToPreRegistro}>Inicia tu Solicitud</NavBtnLink> */}
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar
