//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SidebarLinkEx//, 
    // SideBtnWap, 
    // SidebarRoute 
} 
from './SidebarElements'

const goToPreCuentasOk = () => {
    window.location = "./CuentasOk"
}

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="services" onClick={toggle}>Nosotros</SidebarLink>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarLink to="industry" onClick={toggle}>Soluciones</SidebarLink>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarLink to="simulador" onClick={toggle}>Simulador</SidebarLink>
                </SidebarMenu>
                {/* {/* <SidebarMenu> */}
                    {/* <SidebarLinkEx href="http://help.tivos.mx/es" target="_blank">FAQ's</SidebarLinkEx> */}
                {/* </SidebarMenu> */}
                 <SidebarMenu>
                     <SidebarLinkEx href="https://tivos.blog/">CuentasOK</SidebarLinkEx>
                 </SidebarMenu>
                {/* <SideBtnWap>
                    <SidebarRoute onClick={goToPreRegistro}>Inicia tu Solicitud</SidebarRoute>
                </SideBtnWap> */}
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
