//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'

import Sidebar from '../SidebarAlt'
import NavbarAlt from '../NavbarAlt'
import Footer from '../Footer'
import BannerVendor from '../HeroSection/BannerVendor'
import SectionVendor from '../HeroSection/SectionVendor'
import VendorServices from '../Services/VendorServices'
import ContactoVendor from '../Contacto/ContactoVendor'
import VendorIntro from '../VendorIntro/VendorIntro'

const VendorProgram = () => {

//==========================================================
//  Menu de navegacion
//==========================================================

const [isOpen, setIsOpen] = useState(false);

const toggle = () => {
    setIsOpen(!isOpen);
};

scroll.scrollToTop();

  return (
    <>
        <Sidebar isOpen = {isOpen} toggle={toggle} />
        <NavbarAlt toggle={toggle}/> 
        <BannerVendor/>
        <VendorIntro/>
        <SectionVendor/>
        <VendorServices/>
        <ContactoVendor/>
        <Footer/>
    </>
  )
}

export default VendorProgram