import React, { useRef,useState } from 'react';
import {
    ContactoContainer,
    ContactoWrapper,
    ContactoH1,
    ContactoHeaderP,
    ContactoRow,
    VendorButton
} from './ContactoElements'
import emailjs from '@emailjs/browser'

import {PopupButton} from '@typeform/embed-react'



const ContactoVendor = () => {
    const form = useRef();
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    };
    
    const BannerButton = {
        width: '100%',
        background: 'none',
        border: 'none',
        fontSize: '1.5em',
        color: '#FFF',
        cursor: 'pointer',
        position: 'relative'
    };

    return (
        <>
            <ContactoContainer id="contacto">
                <ContactoWrapper>
                    <ContactoH1>Buscamos exponenciar tu operación.</ContactoH1>
                    <ContactoHeaderP>Platiquemos del por qué entendemos mejor que nadie tus retos como empresario.</ContactoHeaderP>
                    <ContactoRow>
                        <VendorButton onMouseEnter={onHover} onMouseLeave={onHover}>
                            <PopupButton  id="a5BO76St" style={BannerButton}>Inicia tu Solicitud</PopupButton>
                        </VendorButton>
                    </ContactoRow>
                </ContactoWrapper>
            </ContactoContainer>
        </>
    )
}

export default ContactoVendor
