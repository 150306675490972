//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
import {FaqsContainer,
    FaqsWrapper,
    FaqsRow,
    Column1,
    ListWrapper,
    ListCard,
    ListH4,
    ListP,
    Column2,
    TextWrapper,
    FaqsH2,
    FaqsP,
    ImgBkg,
    InnerList,
    InnerListItem
} from './VendorIntroElements'
import BkgImg from '../../images/vendor.png'

const goToFaq = () => {
    window.location = "http://help.tivos.mx/es"
}

const VendorIntro = () => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])


    return (
        <>
            <FaqsContainer id="faqs">
                <FaqsWrapper>
                    <FaqsRow>
                        <Column1>
                            <ImgBkg src={BkgImg}/>
                        </Column1>
                        <Column2>
                            <TextWrapper>
                                <FaqsP>El Arrendamiento Puro es un esquema financiero con el cual pagas una renta mensual por el uso de equipos activos durante el periodo establecido en un contrato.</FaqsP>
                            </TextWrapper>
                        </Column2>
                    </FaqsRow>
                </FaqsWrapper>
            </FaqsContainer>
        </>
    )
}

export default VendorIntro
