//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
import {FaqsContainer,
    FaqsWrapper,
    FaqsRow,
    Column1,
    ListWrapper,
    ListCard,
    ListH4,
    ListP,
    Column2,
    TextWrapper,
    FaqsH2,
    FaqsP,
    InnerList,
    InnerListItem
} from './FaqsElements'
import {Button} from '../ButtonElement'

const goToFaq = () => {
    window.location = "http://help.tivos.mx/es"
}

const Faqs = () => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])


    return (
        <>
            <FaqsContainer id="faqs">
                <FaqsWrapper>
                    <FaqsRow>
                        <Column1>
                            <ListWrapper>
                                <ListCard>
                                    <ListH4>¿Qu&eacute; es el arrendamiento puro?</ListH4>
                                    <ListP>El arrendamiento puro, tambi&eacute;n conocido como leasing, es un esquema que te brinda la oportunidad de pagar una renta mensual por el uso de equipos activos (maquinaria, equipos, unidades de transporte y otros activos) durante el periodo establecido en un contrato.</ListP>
                                </ListCard>
                                <ListCard>
                                    <ListH4>¿Qu&eacute; beneficios otorga el arrendamiento a mi negocio?</ListH4>
                                    <ListP>Rentar en este esquema trae diversos beneficios a tu negocio, por ejemplo:
                                        <InnerList>
                                            <InnerListItem>1. La renta pagada es 100% deducible de impuestos, lo cual optimiza tu carga fiscal.</InnerListItem>
                                            <InnerListItem>2. Obtienes la maquinaria y equipo necesarios para tu operación, sin necesidad descapitalizar tu empresa.</InnerListItem>
                                            <InnerListItem>3. El riesgo de obsolescencia se elimina, porque tienes la opción de renovar el equipo al término de tu contrato y mantenerte a la vanguardia tecnológica.</InnerListItem>
                                            <InnerListItem>4. Optimizas tus activos y obtienes liquidez mediante el Sale &amp; Lease-Back. Te compramos tus activos y te los rentamos de regreso.</InnerListItem>
                                        </InnerList>
                                    </ListP>
                                </ListCard>
                                <ListCard>
                                    <ListH4>¿Con qué industrias trabaja tivos^?</ListH4>
                                    <ListP>Brindamos soluciones integrales de leasing a distintas industrias en México para personas morales y personas físicas con actividad empresarial:  procesos industriales, manufactura, agroindustria, alimentos y bebidas, transporte y logística, procesos tecnológicos, oficinas, hotelería, educación y energías verdes.</ListP>    
                                </ListCard>
                                <ListCard>
                                    <ListH4>¿Cuál es el proceso para obtener un arrendamiento con tivos^?</ListH4>
                                    <ListP>Solicitar un financiamiento con nosotros es muy sencillo.
                                        <InnerList>
                                            <InnerListItem>1. Comienza tu aplicación usando nuestro simulador</InnerListItem>
                                            <InnerListItem>2. Escríbenos mediante el Chat o por correo a contacto@tivos.mx</InnerListItem>
                                            <InnerListItem>3. Si deseas agilizar el proceso de pre-aprobación a menos de 24 horas, te recomendamos compartirnos tu CIEC</InnerListItem>
                                            <InnerListItem>4. Realizamos una consulta rápida y sencilla de tu historial crediticio</InnerListItem>
                                            <InnerListItem>5. Si todo está en orden, solicitaremos tu apoyo para que nos proporciones algunos documentos legales y detalle del activo deseado</InnerListItem>
                                            <InnerListItem>6. ¡Listo! Tu financiamiento quedará aprobado, nos contactamos con el proveedor del activo, y hacemos el desembolso</InnerListItem>
                                        </InnerList>
                                    </ListP>
                                </ListCard>
                                <ListCard>
                                    <ListH4>¿En cuánto tiempo se obtiene la aprobación del arrendamiento para mi negocio?</ListH4>
                                    <ListP>El tiempo dependerá de la información que nos proporciones. Si nos apoyas con tu CIEC desde el inicio, el proceso se agiliza y toma de 2 a 3 días hábiles. Un ejecutivo te contactará para conocerte antes de aprobar tu arrendamiento.</ListP>
                                </ListCard>
                            </ListWrapper>
                        </Column1>
                        <Column2>
                            <TextWrapper>
                                <FaqsH2>Preguntas Frecuentes</FaqsH2>
                                <FaqsP>En tivos^ nos gusta ser claros y directos. Por lo que te compartimos nuestra respuesta a dudas comunes que anteriormente nos han hecho 
                                    personas como tú. Gente que quiere hacer crecer su negocio.</FaqsP>
                            </TextWrapper>
                            {/* <Button onClick={goToFaq} primary={true} dark="true" >Otras Preguntas</Button> */}
                        </Column2>
                    </FaqsRow>
                </FaqsWrapper>
            </FaqsContainer>
        </>
    )
}

export default Faqs
