//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
// import { animateScroll as scroll } from 'react-scroll/modules'
import Video from '../../images/grupo_1_crop.png'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, HeroSubP, TivosGreen } from './HeroElements'

function SectionVendor() {
    const [hover, setHover] = useState(false)
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () =>{
        if(window.scrollY >= 80){
            setScrollNav(true)
        }else{
            setScrollNav(false)
        }
    }

    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    },[])

    const onHover = () => {
        setHover(!hover)
    }

    const gotoForm = () => {};

    return (
        <HeroContainer id="home" vendor>
            <HeroBg>
                <VideoBg src={Video}/>
            </HeroBg>
            <HeroContent vendor>
                <HeroH1 green>¿Qué es el Vendor Program?</HeroH1>
                <HeroH1>Tú vendes y nosotros financiamos el activo de tu cliente con <color style={{color: '#1EB445'}}>Arrendamiento Puro.</color></HeroH1>
            </HeroContent>
        </HeroContainer>
    )
}

export default SectionVendor
