//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from "styled-components";
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS} from 'react-scroll'

export const FooterContainer = styled.footer`
    background-color: #101522;
`;

export const FooterWrapp = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: left;
    border-top: solid 1px #1EB445;
    border-bottom: solid 1px #1EB445;

    @media screen and (max-width: 820px){
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div`
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;

    @media screen and (max-width: 820px){
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 480px){
        margin: 0 0 0 10%;
        padding: 10px;
        width: 100%;
        align-items: center;
    }

    @media screen and (max-width: 380px){
        margin: 0 0 0 0%;
        padding: 10px;
        width: 100%;
        align-items: center;
    }
`;

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`;

export const FooterLinkPage = styled(LinkR)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    cursor: pointer;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;
export const FooterLink = styled(LinkS)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    cursor: pointer;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;
export const FooterLinkEx = styled.a`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;
    cursor: pointer;

    &:hover{
        color: #01bf71;
        transition: 0.3s ease-out;
    }
`;

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrapp = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 1000px){
        flex-direction: row;
    };
`;

export const SocialLogo = styled(LinkR)`
    color: #fff;
    // justify-self: start;
    // cursor: pointer;
    // text-decoration: none;
    // font-size: 1.5rem;
    // display: flex;
    // align-items: center;
    // margin-bottom: 16px;
    // font-weight: bold;
`;

export const WebsiteRights = styled.small`
    color: #fff;
    margin-bottom: 16px;

    @media screen and (max-width: 1000px){
        margin-left: 100px;
      }
    @media screen and (max-width: 600px){
        margin-left: 0px;
      }
    
`;

export const SocialIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;

    @media screen and (max-width: 1000px){
        
      }
`;

export const SocialIconLink = styled.a`
    color: #fff;
    font-size: 24px;
    margin-left: 40%;
`;

export const FooterLogo = styled.div`
    // width: auto;
    // height: auto;
    // cursor: pointer;
`;

export const Logo = styled.img`
    margin-left: 120%;
    margin-top: 8%;
    max-width: 50%;

    @media screen and (max-width: 1000px){
        margin-left: 60%;
      }

    @media screen and (max-width: 480px){
        margin: 2% 0 2% 37%;
    }

    @media screen and (max-width: 380px){
        margin: 2% 0 2% 28%;
    }
`;