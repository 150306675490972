//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: May 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from "styled-components";
import {Link} from 'react-scroll'

export const Button = styled(Link)`
    border-radius : 50px;
    background: ${({ primary }) => (primary ? '#1EB445' : '#fff')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark })=> (dark ? '#fff' : '#1EB445')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')}
    outline: none;
    border: none;
    cursor: ${({vendor}) => (vendor ? 'default': 'pointer')};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border: solid 1px ${({ primary }) => (primary ? '#1EB445' : '#1EB445')};
    margin-top: ${({ margin }) => (margin ? '20px' : '10px')};
    margin-bottom: ${({ marginB }) => (marginB ? '10%' : '0%')};
    margin-left: ${({ margin }) => (margin ? '3%' : '0%')};
    margin-right: ${({ margin }) => (margin ? '3%' : '0%')};
    width: ${({ small }) => (small ? '50%': ({ medium }) => (medium ? '75%': ({ extraSmall }) => (extraSmall ? '25%': '100%')))};

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#1EB445')};
        color: ${({ primary }) => (primary ? '#1EB445' : '#fff')};
        border: solid 1px #1EB445;
    }
`;
