//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import { 
    ServicesContainer, 
    ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    // ServicesIcon2, 
    ServicesH2, 
    ServicesP 
} 
from './ServicesElements'
import Icon1 from '../../images/sales.svg'
import Icon2 from '../../images/flex.svg'
import Icon3 from '../../images/benefits.svg'
import Icon4 from '../../images/noRisk.svg'
import Icon5 from '../../images/training.svg'
const VendorServices = () => {
    return (
        <>
        <ServicesContainer>
            <ServicesH1>Nuestros Beneficios</ServicesH1>
        </ServicesContainer>
        <ServicesContainer id="services" vendor>
            <ServicesWrapper vendor>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2 vendor >Incrementa tus ventas</ServicesH2>
                    <ServicesP>Financiamiento como tu herramienta de ventas.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2 vendor >Flexibilidad y personalizaci&oacute;n</ServicesH2>
                    <ServicesP>Esquemas flexibles de financiamiento para tus clientes.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2 vendor >Beneficios Fiscales</ServicesH2>
                    <ServicesP>100% deducible de impuestos.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2 vendor >Sin Riesgo</ServicesH2>
                    <ServicesP>Absorbemos el riesgo del financiamiento.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon5} />
                    <ServicesH2 vendor >Capacitaci&oacute;n</ServicesH2>
                    <ServicesP>Capacitaci&oacute;n a tu fuerza de ventas.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
            
        </>
    )
}

export default VendorServices