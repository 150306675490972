import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Video from '../../images/grupo_1.png'
import Footer from '../Footer'
import { HeaderContainer, HeaderBg, VideoBg, HeaderContent, HeaderH1} from '../Header/HeaderElements'
import { BodyContainer, BodyWrapper, BodyTitle, BodyParagraph, BodySubTitle, BodyList, ListTitle, ListItem, BodySubSubTitle, BodyFooter} from '../Documents/DocumentsElements'

const AvisoPrivacidad = () => {
    
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    scroll.scrollToTop();
    
    // window.intercomSettings = {
    //     app_id: "d7vcqtjn"
    //   };
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d7vcqtjn'
    // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d7vcqtjn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

    return (
        <>
            <Sidebar isOpen = {isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/> 
            <HeaderContainer id="AvisoPrivacidad">
                <HeaderBg>
                    <VideoBg src={Video} />
                </HeaderBg>
                <HeaderContent>
                    <HeaderH1>Aviso de Privacidad</HeaderH1>
                </HeaderContent>
            </HeaderContainer>
            <BodyContainer>
                <BodyWrapper>
                    <BodyTitle>AVISO DE PRIVACIDAD INTEGRAL DMR KAPITAL, S.A.P.I. DE C.V.</BodyTitle>
                    
                    <BodyParagraph>El presente Aviso de Privacidad es celebrado entre DMR KAPITAL, S.A.P.I de C.V., con domicilio en Calle Culiac&aacute;n No. 123, interior 504, Colonia Hip&oacute;dromo, 
                        C&oacute;digo Postal 061000, Alcald&iacute;a Cuauht&eacute;moc, Ciudad de M&eacute;xico, M&eacute;xico y, el titular de la información, quién expresamente da su consentimiento para el tratamiento de los mimos, a efecto de 
                        garantizar la privacidad y el derecho a la autodeterminación informativa de las personas, de conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en 
                        Posesión de los Particulares.</BodyParagraph>
                    <BodyParagraph>Este Aviso de Privacidad que está a su disposición para ser consultado en todo momento en la página de Internet http://www.tivos.mx, establece que Tivos es el responsable del 
                        tratamiento de sus Datos Personales y, prevé: el tratamiento que se le da a sus Datos Personales; las medidas de control y prevención tomados para asegurar que su información está 
                        segura y permanezca confidencial; y, el procedimiento para ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición, de acuerdo con lo dispuesto en los artículos 15, 16 
                        y de la Ley y, 23 y 27 del Reglamento de la Ley. Tivos informa y el Usuario está de acuerdo en que, al proporcionar sus Datos Personales y patrimoniales a Tivos, a excepción de que 
                        manifieste lo contrario, consiente el tratamiento de estos de conformidad con lo establecido en este Aviso de Privacidad.</BodyParagraph>
                    
                    <BodySubTitle>I. DEFINICIONES.</BodySubTitle>

                    <BodyParagraph>Para efectos del presente Aviso de Privacidad, los términos que se indican a continuación tendrán los significados que en este documento se señalan, ya sea que estén redactados 
                        en singular o en plural:</BodyParagraph>
                    
                    <BodyList>
                        <ListItem><ListTitle>Aviso de Privacidad:</ListTitle> El presente documento electrónico generado por el Responsable que es puesto a disposición del Titular previo al tratamiento de sus Datos Personales, de conformidad con el 
                            artículo 15 de la Ley.</ListItem>
                        <ListItem><ListTitle>Cookie:</ListTitle> Es un fragmento de texto que se envía y recibe entre el navegador web y el servidor al que accede. Su finalidad original es ofrecer un mecanismo de administración de estado
                            entre un navegador web y un servidor.</ListItem>
                        <ListItem><ListTitle>Datos Personales:</ListTitle> Cualquier información concerniente a una persona física identificada o identificable.</ListItem>
                        <ListItem><ListTitle>Derechos ARCO:</ListTitle> Derechos del titular de los Datos Personales a solicitar al responsable el Acceso, Rectificación, Cancelación u Oposición respecto de dichos datos, conforme a lo 
                            previsto en la Ley y su Reglamento.</ListItem>
                        <ListItem><ListTitle>Encargado:</ListTitle> La persona física o moral que sola o juntamente con otras personas físicas o morales trate Datos Personales a nombre y mediante instrucción del Responsable, 
                            conforme a los procesos, términos y condiciones que este le señale.</ListItem>
                        <ListItem><ListTitle>Ley:</ListTitle> Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</ListItem>
                        <ListItem><ListTitle>Medios electrónicos y digitales:</ListTitle> El Sitio o, cualquier otro medio electrónicos y digitales como lo son teléfono, video, audio u otras tecnologías.</ListItem>
                        <ListItem><ListTitle>Reglamento:</ListTitle> Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</ListItem>
                        <ListItem><ListTitle>Responsable/Tivos:</ListTitle> Se trata de DMR KAPITAL, S.A.P.I. DE C.V., y sus filiales y sucursales que, actuando en lo individual o en conjunto con otras entidades del mismo Grupo Empresarial, 
                            recabe Datos Personales para su tratamiento conforme a las finalidades señaladas en este Aviso de Privacidad.</ListItem>
                        <ListItem><ListTitle>Sitio:</ListTitle> La plataforma digital disponible en la página web http://www.tivos.mx y/o a través de cualquier otro subdominio.</ListItem>
                        <ListItem><ListTitle>Servicios:</ListTitle> Solicitud por parte del Usuario, otorgamiento por parte de Tivos y, en general, todas y cada una de las actividades y transacciones en materia de arrendamiento puro 
                            que se realizan a través del Sitio.</ListItem>
                        <ListItem><ListTitle>Titular/Usuario:</ListTitle> El titular de la información, Datos Personales y/o patrimoniales.</ListItem>
                        <ListItem><ListTitle>Transferencia:</ListTitle> Toda comunicación de datos realizada a persona distinta del responsable o encargado del tratamiento.</ListItem>
                        <ListItem><ListTitle>Tratamiento:</ListTitle> La obtención, uso, divulgación o almacenamiento de Datos Personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento,
                            transferencia o disposición de Datos Personales.</ListItem>
                    </BodyList>

                    <BodySubTitle>II. ¿QUIÉNES SOMOS? ¿DÓNDE PUEDE CONTACTARNOS?</BodySubTitle>

                    <BodyParagraph>Tivos como responsable del manejo, administración y tratamiento de sus Datos Personales es una sociedad mercantil constituida conforme a las leyes de los Estados Unidos 
                        Mexicanos que pone a disposición del Titular el Sitio que facilita la solicitud por parte del Usuario, otorgamiento por parte de Tivos y, en general, las transacciones en materia 
                        de arrendamiento puro.</BodyParagraph>
                    <BodyParagraph>Usted podrá contactar al Área de Protección de Datos Personales de Tivos mediante el correo electrónico protecciondatospersonales@tivos.mx, a través de nuestro sitio web https://www.tivos.mx o, 
                        directamente en nuestras oficinas ubicadas en Calle Culiac&aacute;n No. 123, interior 504, Colonia Hip&oacute;dromo, C&oacute;digo Postal 061000, Alcald&iacute;a Cuauht&eacute;moc, Ciudad de M&eacute;xico, M&eacute;xico.</BodyParagraph>
                    
                    <BodySubTitle>III. ¿QUÉ DATOS PERSONALES TRATAMOS?</BodySubTitle>
                    
                    <BodyParagraph>Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad y que resultan necesarias para la adecuada contratación de productos, operaciones y/o Servicios de Tivos, 
                        se requieren datos de identificación y contacto, fiscales, patrimoniales y/o financieros, laborales y, biométricos limitados a reconocimiento facial y/o huella dactilar, que son obtenidos cuando el 
                        Usuario nos los proporciona o, en su caso, a partir de los formatos que Tivos le entrega y el Usuario requisita. Los Datos Biométricos que se recaben, solo serán tratados para corroborar la identidad 
                        del Usuario y, serán obtenidos de forma directa por Tivos o a través de Medios Electrónicos y de autenticación puestos a su disposición para su aceptación expresa.</BodyParagraph>
                    <BodyParagraph>Desde este momento, el Titular declara bajo protesta de decir verdad que, respecto de todos y cada uno de los Datos Personales que proporciona a Tivos de otras personas, como lo son 
                        accionistas, apoderados, representantes legales, obligados solidarios, garantes, avales, proveedores, beneficiarios, familiares y cónyuge y/o, cualquier otra figura legal relacionada con los Servicios; 
                        ha recibido el consentimiento expreso de estos para ser entregados y, en el caso particular de sus dependientes económicos, detenta la patria potestad que le faculta a transmitir su información. 
                        De la misma forma, el Titular se obliga a informar a esas personas sobre el tratamiento de sus Datos Personales y el contenido de este Aviso de Privacidad. El consentimiento expreso del Titular 
                        para el tratamiento de los Datos Personales es recabado a través del Sitio o por cualquier otro Medio Electrónico y, en su caso, a través de agentes, promotores, comisionistas, proveedores o socios 
                        comerciales de Tivos.</BodyParagraph>
                    <BodyParagraph>Tivos tratará sus Datos Personales únicamente por el tiempo que sea necesario en cumplimiento de las finalidades previstas en este Aviso de Privacidad y disposiciones legales aplicables.</BodyParagraph>
                    
                    <BodySubTitle>IV. ¿PARA QUÉ FINALIDADES TRATAMOS SUS DATOS PERSONALES?</BodySubTitle>

                    <BodyParagraph>Los Datos Personales que nos proporciona, los que se obtengan por referencias personales y los que se generen durante el uso y prestación de los Servicios, resultan necesarios para que 
                        Tivos pueda desarrollar todas las actividades necesarias, directas e indirectas, para ofrecerle un servicio completo, transparente, eficiente y efectivo de acuerdo con sus términos y condiciones y, 
                        en su caso, de acuerdo con el contrato celebrado por virtud del cual el Titular entable una relación jurídica con Tivos, se tratarán para:</BodyParagraph>
                    
                    <BodyList>
                        <BodySubSubTitle>IV.1. FINALIDADES PRINCIPALES.</BodySubSubTitle>
                        <ListItem>Identificar al Usuario y proporcionar los servicios que solicite.</ListItem>
                        <ListItem>Creación del perfil del Usuario.</ListItem>
                        <ListItem>Procesar las solicitudes, como de los Servicios, que realice el Usuario a través del Sitio (y otros medios electrónicos).</ListItem>
                        <ListItem>Gestión, control, administración, procesamiento y, en su caso, otorgamiento de los servicios.</ListItem>
                        <ListItem>Verificar, confirmar y validar la identidad del Usuario, así como la veracidad de la información que proporciona como propia, referencias, obligados solidarios, avales, según resulte aplicable.</ListItem>
                        <ListItem>Verificación e investigación de capacidad crediticia del Usuario y/o quiénes señale como sus obligados solidarios y/o avales.</ListItem>
                        <ListItem>Consultar y realizar investigaciones crediticias ante sociedades de información crediticia que se estimen convenientes.</ListItem>
                        <ListItem>Realizar el análisis de la información proporcionada por el Usuario, a fin de determinar conforme a las disposiciones legales vigentes, el nivel de riesgo, viabilidad de pago, perfil transaccional y, 
                            cualquier otra evaluación que sea requerida por la normatividad aplicable.</ListItem>
                        <ListItem>Verificar las huellas dactilares y/o cualquier elemento de reconocimiento facial proporcionadas por el Usuario en los registros que administra el Instituto Nacional Electoral o, cualquier otra 
                            autoridad que emita documentos oficiales en los Estados Unidos Mexicanos, incluir el resultado de la verificación en el expediente de identificación y resguardar la información para la solicitud de los Servicios.</ListItem>
                        <ListItem>Transmitir la información de Usuarios a terceros que realizan alguna operación relacionada con los productos que ofrece Tivos. En algunos casos, la Clave CIEC proporcionada por el Usuario a través de algún 
                            subdominio de tivos.mx, no puede ser almacenada ni compartida dada las características de seguridad de la presente implementación tecnológica (cifrado). Por ende, ningún operador del Sitio podrá observar los 
                            caracteres que componen la CIEC (ni de forma manual ni por medio de programas computacionales). La clave CIEC la proporcionará el Usuario a Tivos para efectos de consultar la información financiera, las declaraciones 
                            anuales de impuestos, la opinión de situación fiscal y la constancia de situación fiscal, a través de un proveedor de datos del SAT. La información financiera que se obtenga de la consulta solo será utilizada para 
                            poder evaluar al Usuario y para ningún otro fin.</ListItem>
                        <ListItem>Incluir al Usuario en sus bases de datos, integrar expedientes, de manera directa o a través de un tercero; así como gestionar, administrar y mantener actualizadas las bases de datos.</ListItem>

                        <br></br>
                        <BodySubSubTitle>IV.2. FINALIDADES SECUNDARIAS.</BodySubSubTitle>
                        <ListItem>Comunicarse y coordinarse con proveedores de Tivos para facilitar pagos de rentas, cuotas y otros servicios, para hacer que los procesos derivados de los Servicios generados en el Sitio sean lo más eficientes posibles.</ListItem>
                        <ListItem>Realizar actividades de inteligencia comercial, gestionar estudios y programas necesarios para determinar hábitos de consumo del Usuario para la implementación de mejoras y actualizaciones en el Sitio.</ListItem>
                        <ListItem>Brindar capacitación continua con relación a los Servicios ofrecidos por Tivos.</ListItem>
                        <ListItem>Elaborar estadísticas y reportes de los servicios y/o productos prestados por Tivos con el objeto de llevar un control interno y seguimiento puntual de los mismos, incluyendo la generación de expedientes para el seguimiento de los Usuarios.</ListItem>
                    </BodyList>

                    <BodySubTitle>V. ¿CON QUIÉN COMPARTIMOS SUS DATOS PERSONALES?</BodySubTitle>

                    <BodyParagraph>Tivos podrá transferir sus Datos Personales para las finalidades antes señaladas, con: (i) el Servicio Postal Mexicano y empresas de mensajería especializada para hacer el envío de documentación del Titulares; 
                        (ii) aseguradoras para la contratación de seguros, garantías y elaborar dictámenes; (iii) el Instituto Nacional Electoral para verificar y validar la autenticidad de los datos y documentos proporcionado por el Titular y
                            relacionados respecto de su identidad; (iv) Sociedades de Información Crediticia para verificar la calidad financiera y económica del Titular; (v) las sociedades controladoras, subsidiarias o afiliadas de Tivos, así como 
                            a la sociedad matriz o a cualquier sociedad de su grupo corporativo que opere bajo los mismos procesos y políticas internas; (vi) autoridades competentes, en caso de ser solicitados, en términos de la legislación aplicable; 
                            (vii) otras sociedades y empresas en caso de ser adquiridos, fusionados, escindidos y/o por cualquier otra operación similar; (viii) terceros incluidos, entidades financieras y entidades comerciales a las que Tivos ceda, 
                            transmita, transfiera, afecte, grave o negocie, en cualquier forma, los derechos que deriven de la relación jurídica que exista entre Tivos y el Titular; (ix) socios comerciales con quien se tengan celebrados contratos para 
                            la comercialización de productos y/o servicios en beneficio del Usuarios; y, (x) en cualquier otro de los supuestos establecidos en los artículos 10 y 37 de la Ley.</BodyParagraph>
                    <BodyParagraph>De acuerdo con lo establecido en los artículos 50 y 53 del Reglamento de La Ley, Tivos podrán realizar remisiones de los Datos Personales con terceros en su carácter de Encargados para su tratamiento por cuenta de 
                        los Responsables de conformidad con lo establecido en el presente Aviso de Privacidad, lo anterior como consecuencia de la existencia de una relación jurídica entre el tercero y los Responsables. Los terceros, en su carácter de 
                        Encargados, sólo recibirán aquellos Datos Personales que sean necesarios para la prestación de los servicios contratados por los Responsables conforme a las instrucciones de éstos o para el cumplimiento legal correspondiente, 
                        guardando estricta confidencialidad respecto de los Datos Personales tratados.</BodyParagraph>
                    <BodyParagraph>Tivos no venderá ni arrendará la información suministrada por el Usuario a terceros, con fines publicitarios y/o comerciales.</BodyParagraph>
                    
                    <BodySubTitle>VI. ¿CÓMO PUEDE EJERCER SUS DERECHOS ARCO?</BodySubTitle>
                    
                    <BodyParagraph>El Titular como propietario de sus datos personales, tiene derecho de acceder, rectificar y cancelar sus Datos Personales, oponerse al tratamiento de estos, limitar su uso o divulgación, o revocar el consentimiento
                            que ha otorgado a Tivos para el tratamiento de dichos datos, enviando una solicitud al correo electrónico protecciondatospersonales@tivos.mx, que deberá contener, al menos, la siguiente información: (i) nombre completo y, 
                            correo electrónico o domicilio para comunicarle la respuesta a la solicitud; (ii) los documentos que acrediten su identidad (i.e. identificación oficial vigente) o, en su caso, la representación legal de la persona que tramita 
                            la Solicitud en su nombre y representación (i.e. carta poder firmada ante dos testigos acompañada de copia de las identificaciones oficiales del otorgante de la carta poder y del representante legal); (iii) una descripción 
                            clara de los Datos Personales respecto de los que busca ejercer alguno de los derechos antes mencionados; y, (iv) cualquier otro elemento o documento que facilite la identificación de sus Datos Personales.</BodyParagraph>
                    <BodyParagraph>En caso de solicitudes de rectificación de Datos Personales, el Titular deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición; en caso de solicitudes de acceso, se 
                        pondrán a su disposición sus Datos Personales a través de copias simples y/o documentos electrónicos.</BodyParagraph>
                    <BodyParagraph>De conformidad con lo dispuesto por La Ley, Tivos cuenta con un plazo de 20 (veinte) días hábiles contados a partir de la fecha de recepción de su Solicitud para notificarle la determinación adoptada a través del 
                        correo electrónico o domicilio que nos haya proporcionado en la solicitud y, en caso de resultar procedente, contará con un plazo adicional de 15 (quince) días hábiles siguientes a la fecha de notificación para hacerla efectiva. 
                        Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.</BodyParagraph>
                    <BodyParagraph>Si tiene alguna duda con relación al ejercicio de sus derechos ARCO, deberá contactarnos vía correo electrónico previamente señalado.</BodyParagraph>
                    
                    <BodySubTitle>VII. ¿CÓMO PUEDE REVOCAR SU CONSENTIMIENTO PARA EL USO DE SUS DATOS PERSONALES?</BodySubTitle>

                    <BodyParagraph>El Titular en todo momento, podrá revocar su consentimiento para el tratamiento de sus Datos Personales conforme al procedimiento previsto en el apartado anterior, haciendo el señalamiento expreso de que desea 
                        revocar su consentimiento. No obstante, debe tener en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna otra obligación legal requiramos 
                        seguir tratando sus Datos Personales. Igualmente, deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el Servicio que nos solicitó o la conclusión de su relación con Tivos.</BodyParagraph>
                    <BodyParagraph>Para más información sobre la protección de sus datos personales puede contactar a nuestra Área de Protección de Datos Personales en los medios de contacto señalados en este Aviso de Privacidad.</BodyParagraph>
                    
                    <BodySubTitle>VIII. ¿CÓMO PROTEGEMOS SUS DATOS?</BodySubTitle>

                    <BodyList>
                        <BodySubSubTitle>VIII.1. ASPECTOS TECNOLÓGICOS.</BodySubSubTitle>
                        <ListItem>Tivos utiliza mecanismos físicos y electrónicos, además de procedimientos y prácticas que buscan salvaguardar la información de los Titulares. Dentro de estos mecanismos, se emplea el uso de tecnología de encriptación de datos u otras tecnologías para proteger la información que el Titular suministra en el Sitio y que es guardada en las bases de datos de Tivos.</ListItem>
                        <ListItem>Además, el Sitio de Tivos está protegido con un certificado SSL y otras tecnologías con fines de seguridad, realizando pruebas de seguridad con frecuencia, procurando asegurar un ambiente lo más seguro posible para sus Usuarios.</ListItem>
                        <ListItem>Tivos guarda la información de los Datos Personales en sus bases de datos, con el fin de proveer un adecuado Servicio al Titular, tutelando la integridad de los datos, a fin de prevenir los fraudes de terceros. Sin perjuicio de lo señalado en los puntos anteriores, el Titular comprende que las medidas de seguridad en Internet no son inexpugnables..</ListItem>

                        <br></br>
                        <BodySubSubTitle>VIII.2. RECOMENDACIONES DE SEGURIDAD AL TITULAR</BodySubSubTitle>
                            <BodyList>
                                <BodySubSubTitle>VIII.2.1. Nombre de Usuario/Cliente.</BodySubSubTitle>
                                <ListItem>El nombre de usuario en el Sitio es elegido por el Titular al momento de registrarse en este. Sin embargo, siempre será verificada la razón/denominación social o nombre de la persona física, para asegurar que la identidad del Titular como usuario del Sitio es fidedigna y así evitar la suplantación de identidad.</ListItem>
                                
                                <BodySubSubTitle>VIII.2.2. Información de identificación personal.</BodySubSubTitle>
                                <ListItem>El perfil de usuario del Titular en el Sitio y, la información contenida en el mismo, nunca aparecerán de forma visible para personas ajenas a Tivos. Tivos podrá desactivar la cuenta de usuario del Titular en el Sitio si identifica que se encuentra realizando acciones que podrían atentar contra su seguridad. Asimismo, Tivos recomienda a los Titulares, mantener siempre esta información en privado, es decir; manejarla en forma personal. El nombre de usuario y contraseña, requeridos para autentificarse en el Sitio, siempre deberán ser de uso exclusivo del Titular.</ListItem>
                                
                                <BodySubSubTitle>VIII.2.3. Contraseña.</BodySubSubTitle>
                                <ListItem>Por seguridad, se recomienda al Titular que se ubique en este supuesto, cambiar su contraseña en el Sitio con frecuencia. El Titular no deberá compartir su contraseña con terceros, pues ésta es de uso exclusivo de este como usuario del Sitio. Si comparte su nombre de usuario y contraseña con terceros,
comprende que esta acción es realizada a su propio riesgo y bajo su propia responsabilidad. Tivos proveerá mecanismos para que el Titular recupere su contraseña en caso de que la haya olvidado, por ende, nunca le solicitará su contraseña a través de ningún medio que no sea al momento de su autentificación en el Sitio.</ListItem>
                                
                                <BodySubSubTitle>VIII.2.4. Sitio.</BodySubSubTitle>
                                <ListItem>Se recomienda al Titular ingresar al Sitio únicamente a través de las URLS http://www.tivos.mx o alguno de los subdominios de tivos.mx. Puede ser el caso de que se contengan en el Sitio links o vínculos a sitios de terceros, que no tienen relación con Tivos y que no se rigen por el Contrato de Uso del Sitio, por lo que Tivos recomienda al Titular revisar las políticas de privacidad y/o seguridad de este tipo de sitios antes de entregar cualquier información personal requerida por los mismos, toda vez que no se hace responsable por la información entregada por el Titularen dichos sitios, ni del uso que éstos hagan con tal información.</ListItem>
                            </BodyList>
                    </BodyList>

                    <BodySubTitle>IX. ¿CUÁL SERÁ EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES?</BodySubTitle>

                    <BodyParagraph>Tivos utilizará sus Datos Personales en estricto apego a las finalidades previstas en el presente Aviso de Privacidad y durante el tiempo que sea necesario de conformidad con las disposiciones legales aplicables. Asimismo, usted en todo momento puede contactarnos en los medios señalados, para atender cualquier tipo de duda, aclaración o sugerencia respecto a sus datos personales.</BodyParagraph>
                    <BodyParagraph>Usted podrá contactar al Área de Protección de Datos Personales de Tivos mediante el correo electrónico protecciondatospersonales@tivos.mx, a través de nuestro sitio web https://www.tivos.mx o, 
                        directamente en nuestras oficinas ubicadas en Calle Culiac&aacute;n No. 123, interior 504, Colonia Hip&oacute;dromo, C&oacute;digo Postal 061000, Alcald&iacute;a Cuauht&eacute;moc, Ciudad de M&eacute;xico, México.</BodyParagraph>
                    <BodyParagraph>Tivos pone a su disposición la siguiente dirección de correo electrónico protecciondatospersonales@tivos.mx para que usted como Titular pueda manifestar en todo momento su negativa a seguir recibiendo comunicados o promociones por parte de nosotros.</BodyParagraph>

                    <BodySubTitle>X. ¿CÓMO USAMOS LAS COOKIES, WEB BEACONS Y/O MECANISMOS SIMILARES?</BodySubTitle>
                    
                    <BodyParagraph>Dado que sin una Cookie (o una solución similar), un servidor web no puede diferenciar entre los distintos usuarios ni determinar ninguna relación entre las visitas de páginas secuenciales efectuadas por el mismo usuario, estas se usan para diferenciar a un usuario de otro y para pasar información de una página a otra durante una sesión de sitio web de un único usuario. Un servidor web usa las Cookies para recopilar datos acerca de un determinado navegador, junto con la información solicitada y enviada por el operador (el visitante) del navegador. Las cookies no identifican a las personas, sino que se definen a sí mismas mediante una combinación de equipo, cuenta de usuario y navegador.</BodyParagraph>
                    <BodyParagraph>Se hace del conocimiento del Usuario que, en el Sitio utilizamos mecanismos remotos o locales de comunicación electrónica, óptima u otra tecnología que permiten recabar Datos Personales relacionados con su actividad en los Medios Electrónicos y los Servicios.</BodyParagraph>
                    <BodyParagraph>Una cookie permite reconocer al Usuario cuando vuelva a visitar los Medios Electrónicos. El Titular puede borrar, bloquear o recibir una advertencia sobre las Cookies en los
navegadores de Internet más comunes por lo que, si requiere hacer esto, debe consultar las instrucciones de su navegador o la pantalla de ayuda para aprender más. Las Cookies permitirán al Titular tomar ventaja de las características más benéficas que los Medios Electrónicos le ofrecen. Los Medios Electrónicos pueden incluir enlaces a sitios web de terceros ajenos a los Responsables, respecto de los cuales este último no asume ninguna titularidad, obligación o responsabilidad alguna.</BodyParagraph>
                    
                    <BodySubTitle>XI. CAMBIOS AL AVISO DE PRIVACIDAD.</BodySubTitle>

                    <BodyParagraph>En cualquier momento, Tivos podrá hacer modificaciones, cambios y/o actualizaciones al presente Aviso de Privacidad que sean necesarias para atender disposiciones legales aplicables, prácticas de privacidad o, por otras causas. El Titular encontrará la versión vigente y con la fecha de más reciente actualización en el Sitio.</BodyParagraph>
                    
                    <BodySubTitle>XII. DECLARACIÓN Y ACEPTACIÓN DEL AVISO DE PRIVACIDAD.</BodySubTitle>

                    <BodyParagraph>Como titular de los Datos Personales, el Titular tiene derecho a acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) en caso de considerar que su derecho a la protección de Datos Personales ha sido vulnerado. El documento electrónico en que se formaliza el Aviso de Privacidad será archivado en la base de datos de Tivos, accesible al Titular en su perfil en el Sitio. En caso de que este último necesite identificar y corregir errores en el envío o en sus datos, podrá contactar a Tivos por los medios contemplados en este instrumento.</BodyParagraph>
                    <BodyParagraph>En este acto, el Titular de los Datos Personales proporcionados a Tivos, manifiesta y reconoce haber leído vía electrónica o en documento impreso y, comprendido el contenido íntegro del Aviso de Privacidad, que conoce su contenido y que, consiente libremente el tratamiento de sus Datos Personales en los términos aquí descritos. La aceptación del presente Aviso de Privacidad, la da el Titular al activar el recuadro correspondiente con la leyenda incluida abajo (cuando se haya aceptado de forma electrónica) o firmándolo autógrafamente (cuando se le hayan proporcionado en documento impreso) y, tiene el carácter de expresa, inequívoca e irrevocable.</BodyParagraph>
                    <BodyParagraph>En caso de haber leído el Aviso de Privacidad vía electrónica en el Sitio, el titular lo ha aceptado a la fecha en que realizó su registro en el Sitio dando “clic” en el “checkbox” con la leyenda: “He leído y acepto el Aviso de Privacidad del Sitio.”</BodyParagraph>

                    <BodyFooter>DMR Kapital, S.A.P.I de C.V., Ciudad de México, México. Marzo de 2023.</BodyFooter>
                </BodyWrapper>
            </BodyContainer>
            <Footer />
        </>
    )
}

export default AvisoPrivacidad
