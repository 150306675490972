//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React, {useState, useEffect} from 'react'
// import { animateScroll as scroll } from 'react-scroll/modules'
import Video from '../../images/grupo_1.png'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, HeroSubP, VendorButton } from './HeroElements'
// import {Button} from '../ButtonElement'

import {PopupButton} from '@typeform/embed-react'

function BannerVendor() {
    const [hover, setHover] = useState(false)
    // const [scrollNav, setScrollNav] = useState(false);

    // const changeNav = () =>{
    //     if(window.scrollY >= 80){
    //         setScrollNav(true)
    //     }else{
    //         setScrollNav(false)
    //     }
    // }

    // useEffect(()=> {
    //     window.addEventListener('scroll', changeNav)
    // },[])

    const onHover = () => {
        setHover(!hover)
    };

    const BannerButton = {width: '100%',background: 'none',border: 'none',fontSize: '1.5em',color: '#fff',cursor: 'pointer',position: 'relative'};
    
    return (
        <HeroContainer id="home" vendor>
            <HeroBg>
                <VideoBg src={Video} vendor/>
                {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
            </HeroBg>
            <HeroContent>
                <HeroP>Vendor Program</HeroP>
                <HeroH1>Somos tu aliado comercial estratégico</HeroH1>
                <HeroSubP>Escala potencialmente las ventas de tu negocio con nosotros</HeroSubP>
                <HeroBtnWrapper>
                    <VendorButton onMouseEnter={onHover} onMouseLeave={onHover}>
                        <PopupButton  id="iOXD1Hon" style={BannerButton}>Comienza como Vendor Program</PopupButton>
                    </VendorButton>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default BannerVendor
