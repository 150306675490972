import styled from "styled-components";

export const ContactoContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #f5f5f5;
`;

export const ContactoWrapper = styled.div`

    @media screen and (max-width: 480px){
        width: 90%; 
    }
`;

export const ContactoH1 = styled.h1`
    color: #1EB445;
    text-align: center;
    font-weight: 300;
    font-size: 2.5em;
    margin-top: 50px;
    margin-bottom: 20px;

    @media screen and (max-width: 480px){
        font-size: 2em;
    }
`;

export const ContactoHeaderP = styled.p`
    text-size: 1rem;
    text-align: center;
    color: gray; 

    @media screen and (max-width: 1000px){
        width: 600px;
        margin-left: 13%;
      }

      @media screen and (max-width: 480px){
        width: 300px;
        margin-left: 10%;
      }

      @media screen and (max-width: 380px){
        width: 300px;
        margin-left: 5%;
      }
`;

export const ContactoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    
    @media screen and (max-width: 480px){
        grid-template-areas: 'col2' 'col1';
        margin-left: 7.5%;
    }

    @media screen and (max-width: 380px){
        margin-left: 6%;
    }
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 10% 5% 10% 35%;
    grid-area: col1;

    @media screen and (max-width: 1000px){
        padding: 3% 1% 3% 10%;
    }
   
    @media screen and (max-width: 480px){
        margin-bottom: 15%;
        margin-left: -10%;
    }
`;

export const Copy = styled.h1`
    color: #1EB445;
    text-align: left;
    font-weight: 300;
    font-size: 2.2em;

    @media screen and (max-width: 1000px){
        font-size: 1.5em;
    }
`;

export const Telefono = styled.p`
    margin-top: 5%;
    font-size: 1.2em;
`;

export const Correo = styled.p`
    margin-top: 1%;
    font-size: 1.2em;
`;

export const Direccion = styled.p`
    color: gray;
    margin-top: 2%;
    font-size: 1.2em;
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 10% 40% 10% 10%;
    grid-area: col2;

    @media screen and (max-width: 1000px){
        padding: 3% 10% 3% 5%;
    }
`;

export const Form = styled.form`
    display: grid;
`;

export const NombreInput = styled.input`
    width: 100%;
    height: 3em;
    border: 2px solid #1EB445;
    border-radius: 15px;
    background: #f5f5f5;
    margin-bottom: 3%;
    padding: 3%;

    &:focus{
        outline: none;
    }
`;

export const CorreoInput = styled.input`
    width: 100%;
    height: 3em;
    border: 2px solid #1EB445;
    border-radius: 15px;
    background: #f5f5f5;
    margin-bottom: 3%;
    padding: 3%;

    &:focus{
        outline: none;
    }

`;

export const MensajeInput = styled.textarea`
    width: 100%;
    height: 10em;
    border: 2px solid #1EB445;
    border-radius: 15px;
    background: #f5f5f5;
    margin-bottom: 3%;
    padding: 3%;

    &:focus{
        outline: none;
    }

`;

export const Button = styled.input`
    border-radius : 50px;
    background: ${({ primary }) => (primary ? '#1EB445' : '#010606')};
    white-space: nowrap;
    padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
    color: ${({ dark })=> (dark ? '#fff' : '#fff')};
    font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')}
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border: solid 1px ${({ primary }) => (primary ? '#1EB445' : '#010606')};
    margin: ${({inline}) => (inline ? '2% 0 2% 30%' : '0 0 0 0')};

    &:hover{
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#1EB445')};
        color: ${({ primary }) => (primary ? '#1EB445' : '#fff')};
        border: solid 1px #1EB445;
    }
`;

export const VendorButton = styled.button`
    border-radius : 50px;
    background: #1EB445;
    white-space: nowrap;
    padding: 12px 30px;
    outline: none;
    border: solid 2px #1EB445;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-top: 20px;
    margin-bottom: 7%;
    width: 60%;

    &:hover{
        transition: all 0.2s ease-in-out;
        border: solid 2px #1EB445;
        box-shadow: 0px 0px 10px #1EB445;
    }
`;