//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import { 
    ServicesContainer, 
    // ServicesH1, 
    ServicesWrapper, 
    ServicesCard, 
    ServicesIcon, 
    // ServicesIcon2, 
    ServicesH2, 
    ServicesP 
} 
from './ServicesElements'
import Icon1 from '../../images/svg-4.svg'
import Icon2 from '../../images/svg-3.svg'
import Icon3 from '../../images/svg-1.svg'
import Icon4 from '../../images/svg-2.svg'
const Services = () => {
    return (
        <>
        <ServicesContainer id="services">
            {/* <ServicesH1>Nuestros Beneficios</ServicesH1> */}
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Crece tu negocio</ServicesH2>
                    <ServicesP>Opera con el activo que necesitas sin descapitalizarte.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Proceso rápido</ServicesH2>
                    <ServicesP>Procesamos tu solicitud de arrendamiento en minutos.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Deducibilidad Fiscal</ServicesH2>
                    <ServicesP>El arrendamiento puro es 100% deducible de impuestos.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2>Control total</ServicesH2>
                    <ServicesP>Escoges el activo y proveedor para tu arrendamiento.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
            
        </>
    )
}

export default Services