//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import React from 'react'
import { 
    IndustryContainer, 
    IndustryH1, 
    IndustryPH, 
    IndustryPHSub, 
    IndustryWrapper, 
    IndustryCard, 
    IndustryIcon, 
    // IndustryIcon2, 
    IndustryH2, 
    IndustryP 
} 
from './IndustryElements'
import Icon1 from '../../images/maquinaria.png'
import Icon2 from '../../images/tecnolog_a.png'
import Icon3 from '../../images/transporte.png'
import Icon4 from '../../images/salud.png'
import Icon5 from '../../images/sector_energ_tico.png'
import Icon6 from '../../images/Agroindustrias.svg'
import Icon7 from '../../images/noun_hotel.png'
import Icon8 from '../../images/noun_helmet.png'
import Icon9 from '../../images/alimentos-y-bebidas.svg'

const Industry = () => {
    return (
        <>
        <IndustryContainer id="industry">
            <IndustryH1>Industrias con las que trabajamos</IndustryH1>
            <IndustryPH>Ubica tu negocio dentro de la gran variedad de industrias con las que trabajamos, ya seas Persona Moral o Física con Actividad Empresarial.</IndustryPH>
            <IndustryPHSub>En tivos^ nos enfocamos en hacer crecer tu negocio.</IndustryPHSub>
            <IndustryWrapper>
                <IndustryCard>
                    <IndustryIcon src={Icon1} />
                    <IndustryH2>Manufactura e industrial</IndustryH2>
                    <IndustryP>Optimiza tus procesos con maquinaria y equipo especializado de vanguardia.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon2} />
                    <IndustryH2>Tecnología</IndustryH2>
                    <IndustryP>El hardware que necesitas, desde laptops y pantallas, hasta servidores y paneles de control.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon3} />
                    <IndustryH2>Transporte y logística</IndustryH2>
                    <IndustryP>Hacemos tu carga más ligera. Arrienda tu flotilla para productos secos o refrigerados, o bien ese tractor que impulsará tu cosecha</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon4} />
                    <IndustryH2>Sector Salud</IndustryH2>
                    <IndustryP>Arrendamos equipo especializado para clínicas, hospitales, consultorios o laboratorios. 
                        Arrendamos equipo para diagnóstico, laboratorio, ultrasonido, tratamiento y más.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon5} />
                    <IndustryH2>Sector Energético</IndustryH2>
                    <IndustryP>Somos el medio para que tu negocio continúe generando: posiciónate en la vanguardia tecnológica con nuevos motores, inversores y/o paneles solares.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon6} />
                    <IndustryH2>Agroindustria</IndustryH2>
                    <IndustryP>Arrendamos maquinaria para producción, industrialización y comercialización de productos del campo.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon7} />
                    <IndustryH2>Hotelería y restaurantes</IndustryH2>
                    <IndustryP>Con tivos^ arrienda equipo operativo para incrementar la experiencia de tus clientes.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon8} />
                    <IndustryH2>Servicios, comercio, construcción y otros</IndustryH2>
                    <IndustryP>Si tu empresa hace negocio en otra o varias otras industrias, 
                        inicia tu proceso de solicitud de igual forma, en tivos^ hacemos el mayor esfuerzo para entender tu operación.</IndustryP>
                </IndustryCard>
                <IndustryCard>
                    <IndustryIcon src={Icon9} />
                    <IndustryH2>Alimentos y bebidas</IndustryH2>
                    <IndustryP>Maquinaria y equipo para procesamiento de materia prima, tanto para el sector alimenticio como para el de bebidas.</IndustryP>
                </IndustryCard>
            </IndustryWrapper>
        </IndustryContainer>
            
        </>
    )
}

export default Industry