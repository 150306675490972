import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarLinkEx, SideBtnWap, SidebarRoute } from './SidebarElements'

const goToPreRegistro = () => {
    window.location = "./Solicitud"
}

const goToPreCuentasOk = () => {
    window.location = "./CuentasOk"
}

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="services" onClick={toggle}>Nosotros</SidebarLink>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarLink to="industry" onClick={toggle}>Soluciones</SidebarLink>
                </SidebarMenu>
                <SidebarMenu>
                    <SidebarLink to="simulador" onClick={toggle}>Simulador</SidebarLink>
                </SidebarMenu>
                {/* {/* <SidebarMenu> */}
                    {/* <SidebarLinkEx href="http://help.tivos.mx/es" target="_blank">FAQ's</SidebarLinkEx> */}
                {/* </SidebarMenu> */}
                 <SidebarMenu>
                     <SidebarLinkEx href="https://tivos.blog/">Blog</SidebarLinkEx>
                 </SidebarMenu>
                <SideBtnWap>
                    <SidebarRoute onClick={goToPreRegistro}>Inicia tu Solicitud</SidebarRoute>
                </SideBtnWap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
