import React, {useState} from 'react'
import { animateScroll as scroll } from 'react-scroll/modules'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import Video from '../../images/grupo_1.png'
import Footer from '../Footer'
import { HeaderContainer, HeaderBg, VideoBg, HeaderContent, HeaderH1} from '../Header/HeaderElements'
import { BodyContainer, BodyWrapper, BodyTitle, BodyParagraph, BodySubTitle, BodyList, ListTitle, ListItem, BodySubSubTitle, BodyFooter} from '../Documents/DocumentsElements'

const TerminosCondiciones = () => {
    
    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    scroll.scrollToTop();

  //   window.intercomSettings = {
  //     app_id: "d7vcqtjn"
  //   };
  // // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/d7vcqtjn'
  // (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/d7vcqtjn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

    return (
      <>
      <Sidebar isOpen = {isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/> 
      <HeaderContainer id="AvisoPrivacidad">
          <HeaderBg>
              <VideoBg src={Video} />
          </HeaderBg>
          <HeaderContent>
              <HeaderH1>Términos y Condiciones</HeaderH1>
          </HeaderContent>
          </HeaderContainer>
          <BodyContainer>
              <BodyWrapper>
                  <BodyTitle>TÉRMINOS Y CONDICIONES <br></br> DMR KAPITAL, S.A.P.I. DE C.V.</BodyTitle>
                  
                  <BodyParagraph>Este contrato celebrado entre DMR KAPITAL, S.A.P.I. DE C.V. (“Tivos”) con domicilio en Sierra Madre 380, Lomas de Chapultepec V Secci&oacute;n, C.P. 11000, Miguel Hidalgo, Ciudad de M&eacute;xico, México y, el usuario de la plataforma http://www.tivos.mx y/o de sus subdominios (el “Sitio”), describe los términos y condiciones generales (los “Términos y Condiciones”) que regulan y son aplicables al uso de los contenidos, productos y servicios ofrecidos por Tivos mediante el Sitio del que es titular.</BodyParagraph>
                  <BodyParagraph>Cualquier persona que desee entrar y/o hacer uso del Sitio, deberá sujetarse y adherirse sin reservas a estos Términos y Condiciones, por lo que Tivos recomienda leerlos cuidadosamente ya que, al utilizar el Sitio, el Usuario manifiesta y reconoce haber leído, aceptado y estar de acuerdo en obligarse de conformidad con los presentes y con cualquier modificación que en el futuro pudiese existir. La aceptación expresa, inequívoca e irrevocable de los Términos y Condiciones la da el Usuario al activar en el Sitio el recuadro correspondiente (“checkbox”) con la leyenda “He leído y acepto los Términos y Condiciones de Uso” (cuando se haya aceptado de forma electrónica) o, firmándolos autógrafamente (cuando se le hayan proporcionado en documento impreso). En todo caso, cualquier persona que no acepte los presentes Términos y Condiciones, deberá abstenerse de utilizar el Sitio y/o adquirir los productos y servicios de Tivos, por lo que no podrá ser considerado como Usuario.</BodyParagraph>
                  
                  <BodySubTitle>I. DEFINICIONES.</BodySubTitle>

                  <BodyParagraph>Para efectos de los presentes Términos y Condiciones, las palabras que se indican a continuación tendrán los significados que en este documento se señalan, ya sea que estén redactados en singular o en plural:</BodyParagraph>
                  
                  <BodyList>
                      <ListItem><ListTitle>Acuerdos específicos:</ListTitle> Obligaciones adicionales a las contenidas en estos Términos y Condiciones, vigentes y aplicables al Usuario.</ListItem>
                      <ListItem><ListTitle>Autentificación:</ListTitle> Acción de acceder al Sitio como Usuario mediante el ingreso de su “Nombre de Usuario” y “Contraseña” en la sección de autentificación del Sitio.</ListItem>
                      <ListItem><ListTitle>Contenidos:</ListTitle> Todo el material del Sitio, incluyendo de forma enunciativa más no limitativa: los textos, datos, artículos, diseños, logotipos, marcas registradas, formularios, documentos, gráficos, fotos, imágenes, contenidos en general, su organización y compilación, códigos fuente, softwares y cualquier otra información contenida en el Sitio.</ListItem>
                      <ListItem><ListTitle>Contraseña o clave de acceso:</ListTitle> Combinación alfanumérica, designada por el Cliente, que se encuentra registrada en las bases de datos de “TIVOS”, la cual es requerida para autentificarse en el Sitio.</ListItem>
                      <ListItem><ListTitle>Contratos Tivos:</ListTitle> Contratos de arrendamiento, préstamo, crédito, enajenación, promesa de compra, factoraje, pignoración, comisión mercantil y/o cualquier otro tipo de contrato, instrumento jurídico y/o mandato que se pueda celebrar en el Sitio entre el Usuario y Tivos.</ListItem>
                      <ListItem><ListTitle>Cuenta:</ListTitle> Instancia del Sitio a la que el Usuario accede a autentificarse, corresponde a la página personal del Usuario en http://www.tivos.mx, por lo cual le permite acceder a distintos servicios, entre los que se encuentran el Perfil de Usuario, registro de información, envío de documentos, eventos históricos y futuros, relativos a los servicios utilizados en el Sitio, documentos compartidos y/o aceptados en el Sitio, entre otros.</ListItem>
                      <ListItem><ListTitle>Firma electrónica:</ListTitle> Los datos en forma electrónica consignados en un mensaje de datos o adjuntados o asociados a éste que identifican al Usuario como firmante con relación al mensaje de datos, incluyendo NIP, clave de acceso al Sitio y la firma digital del titular de la Cuenta.</ListItem>
                      <ListItem><ListTitle>Marcas:</ListTitle> Signos perceptibles por los sentidos y susceptibles de representarse con la denominación “Tivos” y/o los logos que la acompañen y/o se utilicen por separado en el Sitio que distingan los productos y/o servicios que presta Tivos.</ListItem>
                      <ListItem><ListTitle>Nombre de Usuario:</ListTitle> Nombre elegido por el Usuario que lo identifica al usar las secciones del Sitio que así lo exigen y que, se encuentra registrado en las bases de datos de Tivos.</ListItem>
                      <ListItem><ListTitle>Perfil de Usuario:</ListTitle> Instancia del Sitio en que el Usuario maneja y publica el conjunto de su información personal.</ListItem>
                      <ListItem><ListTitle>Política de Privacidad:</ListTitle> Reglamentación obligatoria en materia de protección de información y datos personales del Usuario, entregados libremente a Tivos para su tratamiento y correcta prestación y/o ejecución de los Servicios.</ListItem>
                      <ListItem><ListTitle>Registro de Usuario:</ListTitle> El proceso por el cual un Visitante procede a completar satisfactoriamente el procedimiento de inscripción en el Sitio, habilitándolo para acceder a ciertos y determinados Contenidos y para utilizar los Servicios disponibles en el Sitio.</ListItem>
                      <ListItem><ListTitle>Servicios:</ListTitle> Solicitud por parte del Usuario, otorgamiento por parte de Tivos y, en general, todas y cada una de las actividades y transacciones en materia de arrendamiento puro que se realizan a través del Sitio.</ListItem>
                      <ListItem><ListTitle>Sitio:</ListTitle> La plataforma digital disponible en la página web http://www.tivos.mx y/o a través de cualquier otro subdominio, propiedad y bajo operación de Tivos, conformada por los Contenidos y herramientas que se encuentran disponibles en ella actualmente y según se modifiquen de tiempo en tiempo.</ListItem>
                      <ListItem><ListTitle>Tivos:</ListTitle> DMR KAPITAL, S.A.P.I. DE C.V., sociedad mercantil legalmente constituida conforme a la legislación de los Estados Unidos Mexicanos, titular del Sitio y de las Marcas, prestadora de los Servicios.</ListItem>
                      <ListItem><ListTitle>Usuario/Cliente:</ListTitle> Persona física o moral que desea realizar o realiza alguna operación con Tivos o, completa su registro de Usuario en el Sitio, y habiendo firmado electrónica o autógrafamente los Términos y Condiciones y el Aviso de Privacidad, es reconocida como Usuario/Cliente indistintamente.</ListItem>
                      <ListItem><ListTitle>Visitante:</ListTitle> Aquella persona que no ha completado con éxito el proceso de Registro del Usuario en el Sitio, así como tampoco ha aceptado los Términos, Condiciones y el Aviso de Privacidad, por lo que no adquiere la calidad de Usuario/Cliente. El Visitante puede navegar por ciertas páginas y contenidos del Sitio; sin embargo, la navegación por ciertos contenidos y secciones se encuentra reservada únicamente para Usuarios del Sitio.</ListItem>
                  </BodyList>
                  
                  <BodySubTitle>II. ASPECTOS GENERALES.</BodySubTitle>
                  <BodyList>
                      <BodySubSubTitle>II.1. Uso del Sitio.</BodySubSubTitle>
                      <ListItem>El Uso del Sitio tiene por objeto poner a disposición de los Usuarios una plataforma que les permite acceder a los Servicios que ofrece Tivos de forma eficaz y eficiente..</ListItem>

                      <br></br>
                      <BodySubSubTitle>II.2. Aceptación.</BodySubSubTitle>
                      <ListItem>Mediante el registro en el Sitio, el Usuario reconoce y declara haber leído, comprendido y aceptado íntegramente los Términos y Condiciones de Uso del Sitio en todas sus partes, aceptando ser regido por el mismo y estando sujeto a las leyes y regulaciones aplicables. Una vez seleccionada la aceptación de los Términos y Condiciones, estos serán vinculantes y obligarán a las partes cuando se hayan aceptado de forma electrónica o “firmado autógrafamente” (cuando se le hayan proporcionado en documento impreso). Si el Usuario no acepta los presentes Términos y Condiciones, deberá abstenerse de acceder, navegar y usar el aludido inmediatamente. Asimismo, no podrá hacer uso de sus servicios ni de la información disponible en las páginas web que lo conforman.</ListItem>
                      
                      <br></br>
                      <BodySubSubTitle>II.3. Facultades de Tivos con relación a los Contenidos del Sitio.</BodySubSubTitle>
                      <ListItem>Tivos podrá hacer cambios en los Contenidos y en el Sitio, en cualquier momento y sin previa notificación a los Usuarios. Asimismo, en este acto se deja constancia que el Sitio podría contener errores técnicos y/o tipográficos, y que Tivos no es ni será responsable de cualquier perjuicio derivado de dichos errores involuntarios.</ListItem>
                      
                      <br></br>
                      <BodySubSubTitle>II.4. Acuerdos específicos.</BodySubSubTitle>
                      <ListItem>Para acceder y hacer uso de ciertas secciones específicas del Sitio y de las páginas web que lo conforman, Tivos podrá requerir de la aceptación por parte del Usuario, de ciertos términos y condiciones más específicos, adicionales a los contenidos en este documento, lo cual se informará oportunamente al Usuario según proceda, para recabar su autorización. En caso de que el Usuario acepte dichas condiciones específicas, las mismas resultarán vinculantes y se entenderán ser adicionales a los presentes Términos y Condiciones, permitiendo según el caso en concreto, sustituir, completar y/o modificar el presente instrumento. Ante el supuesto de controversia entre las disposiciones contenidas en el presente documento y, una o más de las contenidas en múltiples Acuerdos específicos, se entenderá que las condiciones y términos contenidas en los últimos, prevalecerán respecto de este documento, para las páginas o secciones específicas a las que se refieran y, en lo general, continuarán vigentes y aplicables los aquí plasmados. En el caso que, (previo requerimiento) el Usuario no acepte los términos y condiciones propuestos en los Acuerdos Específicos, Tivos se encontrará plenamente facultado para denegar a dicho Usuario el acceso a las secciones específicas relevantes del Sitio y de las páginas web que lo conforman, bajo el tenor que Tivos podrá cambiar y/o restringir el acceso a todo o a una sección del Sitio, sin previa notificación al Usuario y sin responsabilidad alguna, ante el supuesto de que el Usuario estime que se le haya generado un daño a partir de esa acción.</ListItem>
                      
                      <br></br>
                      <BodySubSubTitle>II.5. Firma electrónica (autenticación).</BodySubSubTitle>
                      <ListItem>El Usuario podrá manifestar su consentimiento respecto de la aceptación los Términos y Condiciones, así como tener acceso al Sitio, mediante el uso de una Firma Electrónica, incluido el NIP, a través de Medios Electrónicos. Es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera. Los Usuarios asumen el compromiso de actualizar los Datos de Registro conforme resulte necesario.</ListItem>
                      
                      <br></br>
                      <BodySubSubTitle>II.6. Autonomía de los Términos y Condiciones.</BodySubSubTitle>
                      <ListItem>Si cualquier Término y/o Condición previsto en este documento de uso del Sitio, fuese declarado inexistente, inválido, inoponible y/o de cualquier modo ineficaz por el Tribunal competente, los demás preceptos de este instrumento, así como los derechos y obligaciones que de ellos emanan, mantendrán su plena vigencia y validez.</ListItem>
                  </BodyList>
                  
                  <BodySubTitle>III. USO DEL SITIO DE SU CONTENIDO.</BodySubTitle>

                  <BodyList>
                      <BodySubSubTitle>III.1. Aspectos generales.</BodySubSubTitle>
                      <ListItem>El Usuario acepta usar el Contenido y el Sitio sólo con propósitos recaídos dentro del marco legal y regulatorio, de acuerdo con la moral, buenas costumbres y buena fe, en armonía con el orden público y, según los Términos y Condiciones del presente documento, así como las especificaciones a cada servicio, contenidos en los Acuerdos Específicos. Tivos no asegura que los Contenidos del Sitio y los servicios prestados a través del mismo son idóneos para su uso y funcionamiento fuera de los Estados Unidos Mexicanos; por tanto, si el Usuario accede a este Sitio desde un país diverso al referido, comprende y acepta que el acceso y uso del Sitio es bajo su responsabilidad y, éste debe asegurarse de cumplir con las leyes y normas específicas de su jurisdicción.</ListItem>
                      <BodySubSubTitle>III.2. Prohibiciones al Usuario.</BodySubSubTitle>
                      <ListItem>El Usuario comprende y acepta que además de las prohibiciones establecidas en otras secciones de este documento, así como lo contemplado en los Acuerdos Específicos, existen prohibiciones específicas en cuanto al uso del Sitio y de sus Contenidos, por lo cual el Usuario no podrá:</ListItem>
                      
                      <BodyList>
                        <ListItem><ListTitle>1.</ListTitle> Usar el Sitio y/o el Contenido de este, para fines que violen las leyes y regulaciones vigentes en los Estados Unidos Mexicanos en armonía con la legislación internacional, ni incurrir en cualquier práctica con el objeto de vulnerar la Ley, la moral, las buenas costumbres o los derechos de cualquier Usuario de Tivos.</ListItem>
                        <ListItem><ListTitle>2.</ListTitle> Distribuir (desde el Sitio): información o contenidos de carácter fraudulento, engañoso (incluyendo publicidad engañosa de otros productos o servicios ajenos a Tivos), discriminatorio, violento, amenazante, de acoso, difamatorio, denigratorio, inmoral, obsceno o pornográfico, que pudiere por dicha condición dañar a terceros y/o a Tivos; información o contenidos que se encuentren protegidos por las leyes de Propiedad Intelectual y que afecten a terceros y/o a Tivos.</ListItem>
                        <ListItem><ListTitle>3.</ListTitle> Usar y/o hackear el Sitio de cualquier forma que pudiere: dañar, inutilizar, deteriorar, afectar o sobrecargar la normal utilización de este, de sus servicios y/o que pudiere afectar los sistemas, plataformas, softwares y hardwares de Tivos.</ListItem>
                        <ListItem><ListTitle>4.</ListTitle> Transmitir o distribuir en el Sitio cualquier virus, programa o elemento electrónico que pudiere dañar o impedir el normal funcionamiento de este, los sistemas, equipos informáticos, y a su vez, interferir con su seguridad, generando un daño a Tivos y/o a terceros;</ListItem>
                        <ListItem><ListTitle>5.</ListTitle> Impedir o interrumpir el uso del Sitio a terceros.</ListItem>
                        <ListItem><ListTitle>6.</ListTitle> Usar cuentas y/o información personal de estos, sin contar con la autorización del tercero titular.</ListItem>
                        <ListItem><ListTitle>7.</ListTitle> Monitorear, copiar o extraer información del Sitio, ya sea utilizando procesos automáticos o manuales.</ListItem>
                        <ListItem><ListTitle>8.</ListTitle> Subir, publicar, postear, transmitir, compartir, guardar o hacer pública en el Sitio o en cualquier otro sitio web, cualquier información privada o de identificación personal de cualquier Usuario de Tivos, incluyendo a modo de ejemplo y no limitativo: la publicación de nombres, direcciones, números de teléfono, direcciones de correo electrónico, direcciones particulares o laborales, Registros Federales de Contribuyentes, información de cuentas corrientes y/o de tarjetas de crédito, entre otras, independiente de que esa información privada o de identificación personal de un tercero se encuentre publicada deliberada o accidentalmente en Tivos de modo visible para los Usuarios.</ListItem>
                        <ListItem><ListTitle>9.</ListTitle> Transmitir la información personal mencionada en el punto anterior a través de emails, blogs, foros o cualquier otro medio de comunicación de cualquier naturaleza.</ListItem>
                        <ListItem><ListTitle>10.</ListTitle> Efectuar un mal uso de cualquier información y contenido, accesibles desde el Sitio, entendiendo el mal uso a modo de ejemplo y no limitativo, las acciones que pudieren menoscabar los derechos fundamentales y las libertades reconocidas constitucionalmente; asimismo, las que induzcan o promuevan acciones delictivas o induzcan a estados de ansiedad y temor a terceros, entre otras.</ListItem>
                      </BodyList>
                  </BodyList>      
                  
                  <BodyParagraph>Cualquier infracción contemplada en estas disposiciones, dará derecho a Tivos para desactivar la Cuenta y Registro del Usuario, motivando la expiración de los presentes Términos y Condiciones, el servicio asociado al mismo, así como la negativa de acceso al Usuario, de acuerdo con las políticas contempladas en este instrumento. Lo anterior, es sin perjuicio del derecho de Tivos de ejercer todas las acciones, tanto civiles como penales, destinadas al resguardo de sus derechos legítimos y los de sus Usuarios.</BodyParagraph>

                  <BodySubTitle>IV. REQUISITOS PARA SER USUARIO/CLIENTE DEL SITIO.</BodySubTitle>

                  <BodyParagraph>Para poder acceder a ser Usuario/Cliente de Tivos mediante el Sitio, las personas deberán crear su Cuenta mediante la selección del nombre de Usuario y Contraseña. El Usuario aceptará los Términos y Condiciones, para también así tener acceso al Sitio, mediante el uso de Firma Electrónica, incluido el NIP o, a través de diversos Medios Electrónicos.</BodyParagraph>
                  <BodyParagraph>Es obligatorio completar el formulario de registro en todos sus campos con datos válidos e información personal exacta, precisa y verdadera. Los Usuarios asumen el compromiso de actualizar los Datos de Registro conforme resulte necesario. Tivos no será responsable por la certeza de los Datos de Registro provistos. Los Usuarios garantizan y responden, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos de Registro ingresados.</BodyParagraph>
                  <BodyParagraph>El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad. La Cuenta es personal, única e intransferible y, está prohibido que un mismo Usuario inscriba o posea más de una Cuenta. El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a esta está restringido al ingreso y uso de su Contraseña, de conocimiento exclusivo del Usuario.</BodyParagraph>
                  <BodyParagraph>El Usuario se compromete a notificar a Tivos en forma inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado de su Cuenta, así como el ingreso por terceros no autorizados a la misma. Tivos desde este momento informa al Usuario y, este es sabedor de que está prohibida la venta, cesión o transferencia de la Cuenta (incluyendo la reputación y calificaciones), a cualquier tercer y bajo cualquier título.</BodyParagraph>
                  <BodyParagraph>En el caso de Usuarios personas morales, la persona que realice el ingreso y registro en el Sitio deberá tener la representación legal con facultades para actos de administración en términos del segundo párrafo del artículo 2554 del Código Civil Federal, con relación al artículo 2° del Código de Comercio, otorgadas ante fedatario público. La persona que realice el registro y/o que haga uso del Sitio en representación de una persona moral, está dando por entendido que cuenta con facultades suficientes para actuar en nombre y por cuenta de ella y que, dicha persona moral acepta adherirse a los presentes Términos y Condiciones.</BodyParagraph>
                  <BodyParagraph>No podrán contratar servicios ofrecidos por Tivos, personas menores de edad o las personas que hayan sido suspendidos temporalmente o inhabilitados definitivamente para ejercer el comercio en términos de lo establecido en el Código de Comercio. Tivos, a su libre determinación, se reserva el derecho de rechazar cualquier solicitud de contratación, incluso de negar el ingreso al Sitio o, de suspender o cancelar cualquier prestación de Servicios, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento en favor del Usuario, ni responsabilidad a cargo de Tivos.</BodyParagraph>

                  <BodySubTitle>V. VERACIDAD DE LA INFORMACIÓN DEL USUARIO.</BodySubTitle>

                  <BodyList>
                    <BodySubSubTitle>V.1. Responsabilidad del Usuario.</BodySubSubTitle>
                    <ListItem>El Usuario se obliga a proporcionar información a Tivos de manera veraz, fidedigna y comprobable para el objeto de la relación comercial y jurídica entre las partes, ya sea a través del Sitio, en formularios de Registro de Usuario u otro medio suministrado por Tivos y/o de otros medios que haya definido con el Usuario (e-mail, correo, teléfono, en las oficinas de Tivos, entre otros). Asimismo, el Usuario es responsable y obligado exclusivo de mantener su información personal siempre actualizada.</ListItem>
                    <ListItem>El Usuario, a través de la aceptación de los actuales Términos y Condiciones, garantiza la autenticidad de todos aquellos datos proporcionados a Tivos y, de aquella información que vaya actualizando paulatinamente, siendo el único responsable ante los daños y perjuicios generados por cualquier información inexacta, falsa, no veraz y/o fidedigna e incompleta que, pudieran recaer sobre sí mismo, sobre Tivos y/o sobre terceros.</ListItem>
                    <BodySubSubTitle>V.2. Prohibiciones.</BodySubSubTitle>
                    <ListItem>El Usuario comprende que no podrá crear diversas cuentas y/o utilizar el Sitio proporcionando datos falsos o información fraudulenta.</ListItem>
                    <BodySubSubTitle>V.3. Verificaciones.</BodySubSubTitle>
                    <ListItem>Tivos se reserva el derecho de verificar la información proporcionada por el Usuario. En caso de comprobar que la información o una parte de ella no es veraz o no corresponde al Usuario que la proporcionó, Tivos podrá desactivar la Cuenta y Registro del Usuario, expirando los Términos y Condiciones aceptados (salvo los criterios que por regla general prevalecerán ante este supuesto), negando el acceso al Usuario, sin perjuicio de las acciones legales que Tivos estime conveniente iniciar contra el Usuario que haya proporcionado información falsa.</ListItem>
                    <BodySubSubTitle>V.4. Sanción.</BodySubSubTitle>
                    <ListItem>Tivos no se hace responsable por las faltas en que incurra el Usuario respecto de esta condición y, según sea el caso, se reserva el derecho de ejercer acciones extrajudiciales y/o judiciales de cualquier materia que pudiese corresponder, en contra del Usuario, ante cuyos gastos erogados, deberán ser cubiertos en su totalidad por el Usuario, sin perjuicio de cubrir igualmente la totalidad de la indemnización y/o pago de daños y perjuicios que pudiese tener lugar.</ListItem>
                  </BodyList>

                  <BodySubTitle>VI. POLÍTICAS ANTISPAM DEL USUARIO.</BodySubTitle>
                  <BodyParagraph>El Usuario se obliga a abstenerse de:</BodyParagraph>
                  <BodyList>
                    <ListItem><ListTitle>1.</ListTitle> Recopilar desde el Sitio datos con finalidad publicitaria.</ListItem>
                    <ListItem><ListTitle>2.</ListTitle> Transmitir, subir, postear, enviar por e-mail o proporcionar cualquier material promocional o publicitario y/o cualquier tipo de comunicación, con fines de venta u otros fines comerciales, que no fue solicitado y/o que no cuente con la previa autorización de los terceros afectados.</ListItem>
                    <ListItem><ListTitle>3.</ListTitle> Transmitir desde el Sitio SPAM, cadenas de mensajes, correo basura o cualquier otro tipo de mensaje interno y/o correo electrónico masivo no solicitado.</ListItem>
                  </BodyList>
                  <BodyParagraph>En caso de que el Usuario incumpla alguna de los supuestos recién listados, Tivos no se hace responsable por las faltas en que incurra el Usuario respecto de esta condición y, según sea el caso, se reserva el derecho de ejercer acciones extrajudiciales y/o judiciales de cualquier materia que pudiese corresponder, en contra del Usuario, ante cuyos gastos erogados, deberán ser cubiertos en su totalidad por el Usuario, sin perjuicio de cubrir igualmente la totalidad de la indemnización y/o pago de daños y perjuicios que pudiese tener lugar.</BodyParagraph>

                  <BodySubTitle>VII. VÍNCULOS A SITIOS WEB DE TERCEROS.</BodySubTitle>
                  <BodyParagraph>El Sitio podrá contener vínculos a otros sitios web de propiedad, operados y/o controlados por terceros. La inclusión en el Sitio de vínculos a sitios de terceros cumple un fin meramente informativo, respecto de Clientes que quisieran obtener información adicional al momento de utilizar los servicios en el Sitio.</BodyParagraph>
                  <BodyParagraph>En consecuencia, Tivos no es responsable de la veracidad y autenticidad de la información proporcionada en estos sitios de terceros, accesibles desde hipervínculos existentes en el Sitio, ni tampoco se puede desprender y/o interpretar que por el hecho de encontrarse estos hipervínculos en las páginas del Sitio. Tivos se encuentra recomendando dichos sitios, sus contenidos y/o servicios ofrecidos en los mismos.</BodyParagraph>
                  <BodyParagraph>Debido a lo anterior, el Usuario comprende y acepta que:</BodyParagraph>

                  <BodyList>
                    <ListItem>&bull; Estos sitios de terceros no tienen relación legal con Tivos, quien no se responsabiliza por los contenidos e información de dichos sitios, por los servicios que ahí pudieran ofrecerse, ni de la disponibilidad de los contenidos de estos.</ListItem>
                    <ListItem>&bull; Los sitios de terceros se encuentran regidos por sus propios términos y condiciones de uso, que no tienen relación con el presente instrumento.</ListItem>
                  </BodyList>

                  <BodyParagraph>En vista de lo anterior, el Usuario reconoce y declara que, accede y utiliza dichos sitios web por su propia cuenta y riesgo, deslindando a Tivos si fuese el caso, de cualquier responsabilidad relacionada o derivada de ello.</BodyParagraph>

                  <BodySubTitle>VIII. EXCLUSIÓN DE RESPONSABILIDAD.</BodySubTitle>
                  <BodyParagraph>Tivos informa al Usuario y este es sabedor de que Tivos no tiene ni tendrá responsabilidad alguna a su cargo ni de resarcimiento de daños y/o perjuicios o, en su caso, pago de ninguna indemnización en su favor, cuando suceda alguno de los supuestos contenidos en este numeral.</BodyParagraph>

                  <BodyList>
                    <ListItem><ListTitle>VIII.1.</ListTitle>El Usuario reconoce y declara que su acceso al Sitio lo efectúa por su propia cuenta y riesgo, en consecuencia, Tivos no es responsabiliza por:</ListItem>
                    <BodyList>
                      <ListItem><ListTitle>1.</ListTitle> Errores, omisiones, inexactitudes y/o falsedades en contenidos publicados por los Usuarios, en especial, en sus perfiles.</ListItem>
                      <ListItem><ListTitle>2.</ListTitle> Cualquier daño o perjuicio al Usuario causado por fallas en el Sitio, software de Tivos y/o en sus sistemas.</ListItem>
                      <ListItem><ListTitle>3.</ListTitle> Cualquier virus que pudiera infectar el equipo del Usuario, producto del acceso y/o utilización del Sitio por parte de este último; por tanto, el Cliente comprende que el uso de este Sitio se emplea bajo su propio riesgo.</ListItem>
                    </BodyList>
                    <ListItem>Producto de lo anterior, si el uso del Sitio deriva en que el Usuario deba incurrir en reparaciones y/o correcciones, contratando algún servicio de mantención de sus equipos computacionales, estos costos deberán ser completa y exclusivamente asumidos por el Usuario, sin tener Tivos responsabilidad alguna de ello.</ListItem>
                    <ListItem>Página 9 de 13
Tivos excluye cualquier responsabilidad por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la presencia de virus u otros elementos informáticos que pudieran producir alteraciones en los sistemas, documentos electrónicos y/o cuentas y perfiles de los Usuarios.</ListItem>
                    <ListItem><ListTitle>VIII.2.</ListTitle> Tivos declara que el sistema del Sitio puede eventualmente no encontrarse disponible debido a dificultades técnicas, fallas de internet o razones ajenas a Tivos, por lo que el Usuario comprende que este no garantiza el acceso continuo e ininterrumpido del Sitio y/o de las secciones de este. En caso de interrupción del servicio, Tivos hará su mejor esfuerzo por restablecer el Sitio y sus servicios con prontitud, sin que de ello pudiera derivarse ningún tipo de obligación ni responsabilidad para Tivos, quien está excluido cualquier responsabilidad por los daños y perjuicios de cualquier naturaleza que puedan derivarse de la falta de disponibilidad y continuidad del funcionamiento del Sitio y de los Servicios.</ListItem>
                    <ListItem><ListTitle>VIII.3.</ListTitle> Respecto del monitoreo permanente o eventual del Sitio, de los Contenidos e información posteados por los Usuarios, de los mensajes internos que envían, entre otras acciones que realicen y que queden registradas y guardadas en la plataforma, el Usuario comprende y acepta que Tivos tiene el derecho de hacerlo de manera electrónica, de tiempo en tiempo y, hacer seguimiento a cualquier información y/o conductas sospechosas de Usuarios, según el propio criterio de Tivos, al servicio y satisfacción de la ley, regulaciones y/o requerimientos gubernamentales o de fiscalización, para asegurar la correcta operación del Sitio, así como para protegerse a sí mismo y a otros Usuarios del Sitio, respecto de conductas que podrían ser fraudulentas y/o atentatorias contra los derechos de los Usuarios.</ListItem>
                    <ListItem><ListTitle>VIII.4.</ListTitle> Conductas fraudulentas, de acoso, denigratorias, difamatorias, discriminatorias, amenazantes, inmorales, obscenas, pornográficas u ofensivas en que incurran Usuarios.</ListItem>
                    <ListItem><ListTitle>VIII.5.</ListTitle> Infracciones a leyes en materia de Propiedad Intelectual, incurridas por Usuarios del Sitio, que hayan publicado o transmitido material de terceros que se encuentran resguardados por dichas leyes.</ListItem>
                    <ListItem><ListTitle>VIII.6.</ListTitle> Suplantación de identidad, es decir, que los Usuarios utilicen datos personales de terceros para crear cuentas en el Sitio. Sin embargo, Tivos se reserva el derecho de verificar las cuentas de manera aleatoria, verificando la veracidad de la información. En caso de detectar fraude, Tivos podrá cancelar la Cuenta, informar a las autoridades competentes y acudir ante los Tribunales competentes, especialmente en casos en los que se haya producido un daño o que denote intención de daño a terceros.</ListItem>
                    <ListItem><ListTitle>VIII.7.</ListTitle> Cualquier infracción a estos Términos y Condiciones que realicen los Usuarios respecto de las obligaciones y prohibiciones estipuladas en este documento.</ListItem>
                  </BodyList>
                  <BodyParagraph>En ninguna circunstancia, los colaboradores, directores, trabajadores, accionistas, socios comerciales, representantes legales, apoderados y/o cualquier otra persona con relación directa y jurídica con Tivos, serán responsables por cualquier daño generado a los Usuarios a partir del uso del Sitio y de sus Contenidos, incluidos los daños generados por errores, omisiones, interrupciones, defectos, retrasos en la información, transmisión, virus computacionales, fallas de sistemas y otros problemas que se produzcan en el Sitio y en sus Contenidos.</BodyParagraph>
                  <BodyParagraph>Respecto de los supuestos señalados previamente, el Usuario no podrá imputarle responsabilidad legal alguna a Tivos, ni exigir indemnizaciones ni pagos por daño emergente y/o lucro cesante, en virtud de perjuicios derivados de los casos que pudiesen originarse.</BodyParagraph>

                  <BodySubTitle>IX. PROPIEDAD INTELECTUAL.</BodySubTitle>

                  <BodyList>
                    <BodySubSubTitle>IX.1. Contenidos protegidos.</BodySubSubTitle>
                    <ListItem>Todo el material del Sitio y sus Contenidos, incluyendo a modo de ejemplo y no limitativo: los textos, datos, artículos, diseños, logotipos, marcas registradas, formularios, documentos, gráficos, fotos, imágenes, contenidos en general, su organización y compilación, códigos fuente, software, entre otros, y cualquier otra información contenida en el Sitio, es propiedad y titularidad exclusiva de Tivos y, consecuentemente se encuentra protegida por las normas de Propiedad Intelectual y por los Tratados Internacionales vigentes y aplicables en esta materia.</ListItem>
                    <ListItem>Así las cosas, el Usuario comprende y acepta expresamente lo anterior, para todos los efectos legales a que haya lugar.</ListItem>
                    <BodySubSubTitle>IX.2. Derechos de Autor y Propiedad Industrial:</BodySubSubTitle>
                    <ListItem>El Sitio, sus Contenidos y, todo lo que intangiblemente lo compone, es propiedad y titularidad única y exclusiva de Tivos, por lo que es la única facultada legalmente para explotarlos en su beneficio y para prestar sus Servicios.</ListItem>
                    <ListItem>La denominación “Tivos” y/o todos y cada uno de los logos que la acompañen y/o se utilicen por separado en el Sitio que sirven para distinguir los productos y/o Servicios que presta Tivos frente a otros, nombres comerciales, avisos comerciales o, cualquier signo distintivo que aparece en el Sitio es propiedad, titularidad y/o objeto de licencia, única y exclusivamente de Tivos.</ListItem>
                    <ListItem>En consecuencia, el Usuario reconoce, declara y acepta que el uso del Sitio y de los Servicios, no le otorga facultad alguna sobre ninguno de estos Derechos de Autor ni de Propiedad Industrial.</ListItem>
                    <BodySubSubTitle>IX.3. Prohibiciones del Usuario en materia de Propiedad Intelectual.</BodySubSubTitle>
                    <ListItem><ListTitle>1.</ListTitle> El Sitio, su Contenido y, en general, ningún Derecho de Autor ni de Propiedad Industrial, podrá ser suprimido, alterado, manipulado, copiado, modificado, reproducido, reutilizado, redistribuido, republicado, expuesto o posteado por el Usuario en ninguna forma y bajo ningún medio, incluyendo a modo de ejemplo y no limitativo, medios electrónicos, mecánicos, grabaciones, fotocopias, entre otros, sin la previa autorización por escrito de Tivos.</ListItem>
                    <ListItem><ListTitle>2.</ListTitle> Nada en el Sitio podrá ser utilizado por terceros sin la previa autorización escrita de Tivos y, para cada vez que se requieran.</ListItem>
                    <ListItem><ListTitle>3.</ListTitle> El acceso, descarga, transmisión e impresión de cualquier Contenido puede ser realizado por el Usuario exclusivamente para fines de uso personal y solo con relación y en función a un correcto uso de los Servicios que le provee Tivos a través del Sitio.</ListItem>
                    <ListItem><ListTitle>4.</ListTitle> Se prohíbe el uso del Contenido de Tivos en cualquier otro sitio, incluyendo links de este en otros sitios web, con o sin el(los) logo(s) y denominación(es) de Tivos.</ListItem>
                    <ListItem><ListTitle>5.</ListTitle> Se prohíbe el uso del Contenido de Tivos en cualquier otro sitio, incluyendo links de este en otros sitios web, con o sin el(los) logo(s) y denominación(es) de Tivos.</ListItem>
                    <ListItem><ListTitle>6.</ListTitle> El Usuario no podrá reproducir un sitio "espejo" del Sitio en su totalidad o en parte, mantenerlo en otros servidores ni publicarlos en Internet.</ListItem>
                    <BodySubSubTitle>IX. 4. Consecuencias del uso no autorizado de los Derechos de Autor y de Propiedad Industrial de Tivos.</BodySubSubTitle>
                    <ListItem>Cualquier uso no autorizado del Contenido del Sitio de acuerdo a esta disposición, constituirá una violación de las leyes y regulaciones en materia de Derechos de Autor y de Propiedad Industrial, reconociendo la facultad de Tivos para desactivar la Cuenta y Registro del Usuario, expirando los presentes Términos y Condiciones, negando el acceso al Usuario conforme lo estipulado en este documento, sin perjuicio del derecho que le asiste a Tivos de ejercer todas las acciones extrajudiciales y judiciales, tanto administrativas, civiles y penales, destinadas al resguardo de sus legítimos derechos de Propiedad Intelectual lato sensu, ante cuyos gastos erogados por Tivos para su defensa, deberán ser cubiertos en su totalidad por el Usuario, sin perjuicio de cubrir igualmente la totalidad de la indemnización y/o pago de daños y perjuicios que pudiese tener lugar.</ListItem>
                    <ListItem>Cualquier pregunta relativa a lo aquí establecido, deberá ser realizada por el Usuario directamente a Tivos mediante alguno de los medios de comunicación de este, ya conocidos por el Usuario.</ListItem>
                  </BodyList>
                  <BodyParagraph></BodyParagraph>
                  <BodyParagraph></BodyParagraph>

                  <BodySubTitle>X.POLÍTICAS DE PRIVACIDAD, CONFIDENCIALIDAD Y RESGUARDO DE INFORMACIÓN.</BodySubTitle>
                  <BodyParagraph>De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento, Tivos establece las Políticas de Privacidad del Sitio en su Aviso de Privacidad que se encuentra disponible en http://www.tivos.mx. El Usuario se obliga a leer detenidamente y aceptar expresamente dichas políticas para poder navegar en el Sitio y utilizar los Servicios que ahí se ofrecen.</BodyParagraph>

                  <BodySubTitle>XI. VIGENCIA Y MOFICIACIONES DE USO DEL SITIO.</BodySubTitle>
                  <BodyList>
                    <BodySubSubTitle>X1.1. Vigencia.</BodySubSubTitle>
                    <ListItem>Este documento se encontrará vigente y será efectivo mientras Tivos mantenga en operación el Sitio. Tivos se reserva el derecho de terminar de ofrecer el Sitio, sus Contenidos y los Servicios que se proveen a través de este, en cualquier momento, previa notificación a los Usuarios en la forma, tiempo y por el medio que Tivos determine.</ListItem>
                    <ListItem>Independientemente de lo anterior, Tivos podrá limitar el Uso del Sitio a su discreción, en cualquiera de los casos ya mencionados previamente y, en cualquiera de los siguientes casos:</ListItem>
                    <BodyList>
                      <ListItem><ListTitle>1.</ListTitle> En caso de comprobar que la información suministrada por el Usuario fuese falsa, incompleta, inexacta, errónea, y/o de cualquier forma poco fidedigna.</ListItem>
                      <ListItem><ListTitle>2.</ListTitle> Si el Usuario incurre en un uso no autorizado del Contenido del Sitio de conformidad con lo dispuesto en las secciones de PROHIBICIÓN del presente documento.</ListItem>
                      <ListItem><ListTitle>3.</ListTitle> En el evento de incurrir el Usuario en alguna conducta u omisión que vulnere las disposiciones antispam contenidas en este instrumento.</ListItem>
                      <ListItem><ListTitle>4.</ListTitle> Si el Usuario comete alguna infracción grave respecto de sus obligaciones derivadas a la aceptación de este documento y/o los Acuerdos Específicos.</ListItem>
                    </BodyList>
                    <ListItem>En tales casos, se entenderá que los Términos y Condiciones de Uso del Sitio y el Registro de Usuario, han expirado desde el momento en el que Tivos notifique al Usuario mediante correo electrónico dirigido a la dirección proporcionada por éste.</ListItem>
                    <ListItem>Sin embargo, las disposiciones que tratan sobre las siguientes materias subsistirán y se mantendrán vigentes, aun después de expirar los términos y condiciones de Uso del Sitio señalados:</ListItem>
                    <BodyList>
                      <ListItem><ListTitle>(i)</ListTitle> Indemnizaciones.</ListItem>
                      <ListItem><ListTitle>(ii)</ListTitle> Declaraciones y Garantías del Usuario.</ListItem>
                      <ListItem><ListTitle>(iii)</ListTitle> Responsabilidades y Exclusiones.</ListItem>
                      <ListItem><ListTitle>(iv)</ListTitle> Jurisdicción.</ListItem>
                      <ListItem><ListTitle>(v)</ListTitle> Cualquier otra materia que por su naturaleza perdure a pesar del término de este Contrato.</ListItem>
                    </BodyList>
                    <BodySubSubTitle>XI.2. Modificaciones a los Términos y Condiciones.</BodySubSubTitle>
                    <ListItem>Tivos podrá en cualquier momento y de tiempo en tiempo, corregir, modificar, agregar, eliminar y actualizar los presentes Términos y Condiciones de Uso del Sitio, previa aceptación efectuada por el Usuario en alguna de las formas y por los medios ya mencionados previamente y determinados para ello por Tivos. En consecuencia, Tivos notificará oportunamente, a través de la publicación de una notificación digital dirigida al Usuario (cuando este intente acceder al Sitio) las modificaciones a realizar respecto de los Términos y Condiciones referidos, tales cambios, se harán efectivos a partir del momento en que el Usuario acepte las modificaciones correspondientes.</ListItem>
                    <ListItem>Lo anterior, sin perjuicio de la facultad de Tivos de condicionar el acceso a ciertas secciones determinadas del Sitio, de conformidad a la aceptación de los Acuerdos Específicos relevantes en cada caso en particular.</ListItem>
                  </BodyList>

                  <BodySubTitle>XII. CONTACTO.</BodySubTitle>
                  <BodyParagraph>Las comunicaciones que Tivos deba o quiera dirigir al Usuario y, viceversa, se efectuarán: (i) mediante correo electrónico designado e informados por Tivos para estos efectos en su Sitio; (ii) desde los números de teléfono desplegados en el Sitio, de lunes a jueves de 9:00 a 18:00 horas y, viernes de 9:00 a 15:00 horas; o, (iii) a través de los mecanismos de contacto que se establezcan en el Sitio.</BodyParagraph>
                  
                  <BodySubTitle>XIII. LEGISLACIÓN Y JURISDICCIÓN APLICABLES.</BodySubTitle>
                  <BodyParagraph>El presente documento y los Acuerdos Específicos se encuentran sujetos y regidos por las leyes vigentes en los Estados Unidos Mexicanos. Cualquier conflicto o controversia surgida con relación a y/o a partir de los presentes Términos y Condiciones de Uso del Sitio y/o sus Acuerdos Específicos, será sometida al conocimiento de los Tribunales competentes de la Ciudad de México.</BodyParagraph>
                  <BodyParagraph>Para estos efectos, y en caso de ser procedente, se entiende que tanto el Usuario como Tivos, renuncian en este acto expresamente a cualquier otro fuero que pudiere corresponderles por razón de su domicilio o por cualquier otro motivo.</BodyParagraph>
                  <BodyParagraph>En vista de lo anterior, todos los procedimientos legales surgidos a partir de los conflictos o controversias se llevarán a cabo en idioma castellano, por lo cual el Usuario acepta notificar a Tivos de manera escrita ante cualquier reclamo o disputa concerniente o relativa a este Sitio y a los Contenidos y Servicios provistos en el mismo, otorgando a Tivos un período razonable para responderle, antes de comenzar cualquier acción legal en su contra.</BodyParagraph>

                  <BodySubTitle>XIV. DECLARACIÓN Y ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES.</BodySubTitle>
                  <BodyParagraph>En este acto, el Usuario manifiesta y reconoce haber leído vía electrónica o en documento impreso y, comprendido el contenido íntegro de los Términos y Condiciones, que conoce su contenido y que, consiente libremente todo su contenido en los términos aquí descritos. La aceptación de los presentes Términos y Condiciones, la da el Usuario al activar el recuadro correspondiente con la leyenda incluida abajo (cuando se haya aceptado de forma electrónica) o firmándolo autógrafamente (cuando se le hayan proporcionado en documento impreso) y, tiene el carácter de expresa, inequívoca e irrevocable.</BodyParagraph>
                  <BodyParagraph>En caso de haber leído los Términos y Condiciones vía electrónica en el Sitio, el titular lo ha aceptado a la fecha en que realizó su registro en el Sitio dando “clic” en el “checkbox” con la leyenda: “He leído y acepto los Términos y Condiciones de Uso del Sitio.”</BodyParagraph>

                  <BodyFooter>DMR Kapital, S.A.P.I de C.V., Ciudad de México, México. Enero de 2022.</BodyFooter>
              </BodyWrapper>
          </BodyContainer>
      <Footer />
  </>
)
}

export default TerminosCondiciones
