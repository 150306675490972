import styled from 'styled-components'

export const IndustryContainer = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

    @media screen and (max-width: 768px){
        height: 700px;
        margin-top: 550px;
    };

    @media screen and (max-width: 480px){
        height: 1250px;
    };
`;

export const IndustryWrapper = styled.div`
    max-width: 1200px;
    margin: 3% auto 5% auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px){
        grid-template-columns: 1fr 1fr;
    };

    @media screen and (max-width: 768px){
        grid-template-columns: 1fr 1fr;
        padding: 0 20px;
        height: 1000;
    };
`;

export const IndustryCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    height: 340px;
    padding: 30px;
    transition: all 0.2s ease-in-out;

    &:hover{
        box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.2);
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        //border-bottom: 2px solid #01bf71;
    }

    @media screen and (max-width: 768px){
        height: 280px;
        padding: 0px;
    };

    @media screen and (max-width: 480px){
        height: 360px;
        padding: 0px;
    };

`;

export const IndustryIcon = styled.img`
    height: auto;
    width: auto;
    margin-bottom: 10%;
    padding: 10%;

`;

export const IndustryIcon2 = styled.img`
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
`;

export const IndustryH1 = styled.h1`
    font-size: 2.5rem;
    font-weight: 300;
    color: #1EB445;
    margin-top: 64px;
    margin-bottom: 20px;

    @media screen and (max-width: 480px){
        font-size: 2rem; 
        text-align: center;
    }
`;

export const IndustryPH = styled.p`
    text-size: 1rem;
    text-align: center;
    width: 50%;
    color: gray; 

    @media screen and (max-width: 480px){
        display: none;
    }
`;

export const IndustryPHSub = styled.p`
    text-size: 1rem;
    text-align: center;
    width: 50%;
    margin-top: 1.5%;
    color: #1EB445; 
    
    @media screen and (max-width: 480px){
        display: none;
    }
`;

export const IndustryH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
`;

export const IndustryP = styled.p`
    text-size: 1rem;
    text-align: center;
    color: gray; 
    padding: 20px auto;

    ${IndustryCard}:hover &{
        color: #1EB445;
    }
`;
