//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from 'styled-components'
import { Link as LinkR} from 'react-router-dom'
import { Link as LinkS} from 'react-scroll'
// import logo from '../../images/tivos-dark-logo.png'

export const Nav = styled.nav`
    background: #fff;
    height: 80px;
    margin-top: -80px;
    display: fex;
    justify-content: center;
    align-items; center:
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px){
        transition: 0.8s all ease;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-wdth: 1100px;
`;


export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: self-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
`;

export const NavLogoWrapper = styled(LinkR)`
    width: 150px;
    height: 80px;
`;

export const NavLogoImg1 = styled.img`
    width: 90%;
    height: 35px;
    margin-left: 30%;
    margin-top: 15%;
    display: ${({show}) => (show ? 'block':'none')};

    @media screen and (max-width: 450px){
        margin-left: 0%;
    }
`;

export const NavLogoImg2 = styled.img`
    width: 90%;
    height: 65px;
    margin-left: 30%;
    margin-top: 5%;
    display: ${({show}) => (show ? 'block':'none')};

    @media screen and (max-width: 450px){
        margin-left: 0%;
    }

`;


export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px){
        display: block;
        position: absolute;
        top: 0; 
        right: 0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: 12%;

    @media screen and (max-width: 960px){
        display: none;
    }
`;

export const NavItem = styled.li`
    height: 80px;
`;

export const NavLinks = styled(LinkS)`
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 1.1rem;

    &.active{
        border-bottom: 3px solid #1EB445;
    }
`;

export const NavLinksEx = styled.a`
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-size: 1.1rem;

    &.active{
        border-bottom: 3px solid #1EB445;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    
    @media screen and (max-width: 768px){
        display: none;
    }
`;

export const NavBtnLink = styled(LinkS)`
    border-radius: 50px;
    background: #1EB445;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;