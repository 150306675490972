//=====================================================//
//=====================================================//
//                  tivos^.mx
//  Author: David Rivera (dave@tivos.mx)
//  Date: March 2022
//  Description: Modulo general para conexiones internas y externas
//
//=====================================================//
//  Changes Log:
//  March 2022: Initial release.
//
//=====================================================//

import styled from "styled-components";

export const FaqsContainer = styled.div`
    @media screen and (max-width: 480px){
        width: 350px;
    }
`;

export const FaqsWrapper = styled.div`
    display: grid;
    height: 600px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 34px 24px;
    justify-content: center;

    @media screen and (max-width: 1000px){
        height: 650px;
      }

    @media screen and (max-width: 480px){
        height: 900px;
        width: 412px;
        margin-left: -2%;
      }

    @media screen and (max-width: 400px){
        height: 1000px;
        width: 390px;
        margin-left: -2%;
      }

    @media screen and (max-width: 380px){
        height: 1000px;
        width: 360px;
        margin-left: -2%;
      }

`;

export const FaqsRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: 'col1 col2';

    @media screen and (max-width: 1000px){
        height: 500px;
      }

    @media screen and (max-width: 480px){
        grid-template-areas: 'col2' 'col1';
    }

`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 60px;
    grid-area: col1;
    height: 500px;

    @media screen and (max-width: 480px){

    }
`;

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 20%;
    grid-area: col2;

    @media screen and (max-width: 480px){
        
    }

`;

export const ListWrapper = styled.div``;

export const ListCard = styled.div`
    background: #fff;
    margin: 2% auto;
    flex-direction: column;
    border-radius: 10px;
    height: auto;
    padding: 20px;
    transition: all 0.2s ease-in-out;
    position: relative;

    &:hover{
        box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.2);
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color: #1EB445;
        //border-bottom: 2px solid #01bf71;
        position: relative;
    }
`;

export const ListH4 = styled.h4`
    margin: 10px auto; 
`;

export const ListP = styled.p`
    color: transparent;
    letter-spacing: .75px;
    font-size: .8em;
    max-height: 0;
    z-index: 13;

    ${ListCard}:hover &{
        max-height: 400px;
        color: gray;
        transition: all 1s ease !important;
    }

`;

export const TextWrapper = styled.div`
    margin-bottom: 20px;
`;

export const FaqsH2 = styled.h1`
    color: #1EB445; 
    margin-bottom: 7.5%;

    @media screen and (max-width: 480px){
        text-align: center;
        font-weight: 300;
    }
`;

export const FaqsP = styled.p`
    color: gray;
    line-height: 1.5;

    @media screen and (max-width: 480px){
        text-align: center;
        display: none;
    }

`;

export const InnerList = styled.div`
    padding: 1%;
`;

export const InnerListItem = styled.div`
    margin: 4px;
`;